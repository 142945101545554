import { useUserSelectionContext } from "../context/UserSelectionContext";

export default function NextButton({ destination, stepData, userData }) {
  const {userSelection, setUserSelection} = useUserSelectionContext();

  function handleOnClick() {
    // Set UserSelection data
    Object.assign(userSelection, userData);
    setUserSelection({...userSelection});

    destination();
  }


  return (
    <div className="mt-2 text-lg-end">
      <button className="btn btn-orange" onClick={handleOnClick}>Next</button>
    </div>
  );
}
