import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import "../assets/css/home.scss";

import homeHeroImg from "../assets/img/hq-home-hero.jpg";
import greenBgImg from "../assets/img/old-green-bg.jpg";

import roofTop from "../assets/img/old-rooftoppv.png";
import solarPoweredBuilding from "../assets/img/old-solarpoweredbuilding.png";

import moneyHouse from "../assets/img/old-money-house.png";
import homePpa from "../assets/img/home-ppa.png";
import residentialImage from "../assets/img/projects/testimonials_project_1.jpg";
import commercialImage from "../assets/img/projects/testimonials_project_13.jpg";

import SEO from "../common/SEO";
import GetQuoteModal from "../common/GetQuoteModal";

import Timeline from "./timeline";

export default function Home() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* SEO */}
      <SEO
        title="Mantula Solar"
      />

      {/* HEADER */}
      <div
        className="hero hero-img-container"
        style={{ backgroundImage: "url(" + homeHeroImg + ")" }}
      >
        <div
          className="overlay color-background overlay d-flex align-items-center flex-column flex-lg-nowrap flex-sm-wrap text-center"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="container hero-container">
            <h1 className="hero-title animate fadeInLeft delay-600 text-center">
              Tailor-made solar<br /> solutions for Africa
            </h1>
            <h5 className="hero-subtitle animate fadeInLeft delay-400 text-center mt-5">
              Free yourself of load-shedding and excessive energy bills.
            </h5>
            <button
              className="hero-button animate fadeInLeft delay-800 mt-4"
              onClick={handleShow}
            >
              GET A FREE QUOTE
            </button>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header className="p-1 bg-light border-0" />
              <GetQuoteModal />
              <Modal.Footer className="bg-light">
                <Button variant="secondary" onClick={handleClose}>
                  Maybe Later
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <a
            href="https://www.pvgreencard.co.za/reg/installers/inst-profile.php?inst=Zn12fg%3D%3D"
            style={{
              position: "absolute",
              bottom: "10px",
              left: "10px",
              zIndex: "100",
            }}
            target="_blank" rel="noreferrer"
          >
            <img
              src={require("../assets/img/pvgreencard.png")}
              alt="PV Green Card"
              style={{ maxHeight: "5vh" }}
            />
          </a>

          <a
            href="#scroll-target"
            role="button"
            className="scroll text-light"
            style={{ zIndex: "999" }}
          >
            <i
              className="bi bi-chevron-down m-0 text-light"
              style={{ fontSize: "2rem" }}
            ></i>
          </a>
        </div>
      </div>

      {/* SOLUTIONS */}
      <div id="scroll-target">
        <div className="container pb-5">
          <div className="section-title-container">
            <h2 className="text-center">Sunshine is Free</h2>
            <h3 className="text-center">We turn it into electricity...</h3>

            <div className="row solutions-row">
              <div className="col-md-6 col-xs-12 text-md-right text-center text-md-start">
                <h2 className="mt-5">
                  <img
                    className="listPic mediaTitleImg"
                    src={roofTop}
                    alt="Rooftop PV"
                  />
                  For Your Home
                </h2>
                <p className="solutions-description secondary-text-color mb-5">
                  Mantula Solar provides you with solutions, that are<br /> tailor-made to your needs and budget.
                </p>
                <a href="/residential#batteryBackup" className="btn btn-primary py-3 px-4 mt-3">Learn More</a>
                <div className="solutions-calculator-container my-5">
                  <a href="/residential#calculator">
                    Price Estimator <i className="bi bi-chevron-right"></i>
                  </a>
                  <p className="secondary-text-color mt-3">
                    Our simple price estimator lets you find the best solution for your residence and gives you a close price estimate.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 d-md-inline">
                <div className="solutions-image float-end" style={{backgroundImage: `url(${residentialImage}`}}>
                </div>
              </div>
            </div>

            <div className="row solutions-row flex-row-reverse">
              <div className="col-md-6 col-xs-12 text-md-right text-center text-md-start">
                <h2 className="mt-5">
                <img
                  className="mr-3 listPic mediaTitleImg "
                  src={solarPoweredBuilding}
                  alt="Solar Powered Businesses"
                />
                  For Your Business
                </h2>
                <p className="solutions-description secondary-text-color mb-5">
                  A continuous power supply is a necessity for all successful businesses. Wherever you work — a farm, a guest lodge, an office — make the sun shine for you!
                </p>
                <a href="/commercial" className="btn btn-primary py-3 px-4 mt-3">Learn More</a>
                <div className="solutions-calculator-container my-5">
                  {/* <a href="/commercial#calculator">
                    Price Estimator <i className="bi bi-chevron-right"></i>
                  </a>
                  <p className="secondary-text-color mt-3">
                    Our simple price estimator lets you find the best solution for your business and gives you a close price estimate
                  </p> */}
                </div>
              </div>
              <div className="col-12 col-md-6 d-md-inline">
                <div className="solutions-image float-start" style={{backgroundImage: `url(${commercialImage}`}}>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* WHY SOLAR */}
      <div
        className="outer bgPic"
        style={{ backgroundImage: "url(" + greenBgImg + ")" }}
      >
        <div className="advantages-overlay py-3">
          <div className="container my-lg-5">
          <div className="row" style={{ flexDirection: "row-reverse" }}>
            <div className="col-lg">
              <h2 className="my-4 text-light fs-1 text-center text-lg-start">Why should you go solar?</h2>
            </div>

            <div className="col-lg">
              <div className="row row-cols-1 row-cols-md-2 g-4 ">
                <div className="col card-container">
                  <div className="card card-flip border border-primary border-2 rounded-4 h-100 align-items-center solar-card">
                    <div className="front text-dark rounded-4">
                      <h3 className="card-title fw-normal">
                        <span
                          className="material-icons mb-3 solar-icon"
                        >
                          verified_user
                        </span>
                        Independent
                      </h3>
                    </div>
                    <div className="back text-dark rounded-3">
                      <div className="container">
                        <p className="card-title m-3 my-2">
                          Declare your independence from load-shedding and
                          rising energy rates from Eskom.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col card-container">
                  <div className="card card-flip border border-primary border-2 rounded-4 h-100 align-items-center solar-card">
                    <div className="front text-dark rounded-4">
                      <h3 className="card-title fw-normal">
                        <span
                          className="material-icons mb-3 solar-icon"
                        >
                          emoji_nature
                        </span>
                        Climate-friendly
                      </h3>
                    </div>
                    <div className="back text-dark rounded-3">
                      <div className="container">
                        <p className="card-title m-3 my-2">
                          Each square meter of solar panels can help reduce CO<sub>2</sub> emissions by an equivalent of 300kg/a.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col card-container">
                  <div className="card card-flip border border-primary border-2 rounded-4 h-100 align-items-center solar-card">
                    <div className="front text-dark rounded-4">
                      <h3 className="card-title fw-normal">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 576 512"
                          className="mb-3 solar-icon"
                        >
                          <path d="M470.7 9.441C473.7 12.49 476 16 477.6 19.75C479.1 23.5 479.1 27.6 480 31.9V32V128C480 145.7 465.7 160 448 160C430.3 160 416 145.7 416 128V109.3L310.6 214.6C298.8 226.5 279.9 227.2 267.2 216.3L175.1 138.1L84.82 216.3C71.41 227.8 51.2 226.2 39.7 212.8C28.2 199.4 29.76 179.2 43.17 167.7L155.2 71.7C167.2 61.43 184.8 61.43 196.8 71.7L286.3 148.4L370.7 64H352C334.3 64 320 49.67 320 32C320 14.33 334.3 0 352 0H447.1C456.8 0 464.8 3.554 470.6 9.305L470.7 9.441zM0 304C0 277.5 21.49 256 48 256H464C490.5 256 512 277.5 512 304V464C512 490.5 490.5 512 464 512H48C21.49 512 0 490.5 0 464V304zM48 464H96C96 437.5 74.51 416 48 416V464zM48 304V352C74.51 352 96 330.5 96 304H48zM464 416C437.5 416 416 437.5 416 464H464V416zM416 304C416 330.5 437.5 352 464 352V304H416zM256 320C220.7 320 192 348.7 192 384C192 419.3 220.7 448 256 448C291.3 448 320 419.3 320 384C320 348.7 291.3 320 256 320z" />
                        </svg>
                        Economical
                      </h3>
                    </div>
                    <div className="back text-dark rounded-3">
                      <div className="container">
                        <p className="card-title m-3 my-2">
                          Most systems pay for themselves after a couple of
                          years, and we help you subsidize up to 20% of the
                          cost!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col card-container">
                  <div className="card card-flip border border-primary border-2 rounded-4 h-100 align-items-center solar-card">
                    <div className="front text-dark rounded-4">
                      <h3 className="card-title fw-normal">
                        <span
                          className="material-icons mb-3 solar-icon"
                        >
                          more_time
                        </span>
                        Long-lasting
                      </h3>
                    </div>
                    <div className="back text-dark rounded-3">
                      <div className="container">
                        <p className="card-title m-3 my-2">
                          The output of solar panels remains almost constant
                          over their lifetime, and they last for decades!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>

      {/* CO2 AVOIDANCE */}
      <div className="section-title-container bg-light py-lg-5 pb-3 pb-lg-5 mb-0 mb-lg-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="co2-avoidance-title text-center text-lg-start">
                  Sell your CO<sub>2</sub> avoidance
              </h2>
              <p className="secondary-text-color">
                Your solar system silently creates clean energy.
                <br />
                In turn, less
                energy has to be generated by the coal-plants<br />
                &rarr; Your system has avoided the emission of CO<sub>2</sub>
                <br />
                <br />
                We help you monetize this avoidance, by filing an application to a german NGO, which subsidizes South African PV-Systems.
                <br />
              </p>
              <div className="co2-reimbursment-outer border border-2 border-primary rounded">
                <p className="co2-reimbursment border border-1 border-secondary rounded">
                  That's how we reimburse you for up to 20% of your system cost!
                </p>
              </div>
              <p className="mb-3 secondary-text-color">
                Give us a call and we'll tell you all about it.
              </p>
              <div className="text-center text-lg-start mt-5 mt-lg-0">
                <a className="btn btn-primary px-3 py-2" href="tel:+270657003395">
                <span
                  className="material-icons me-2"
                  style={{ verticalAlign: "middle" }}
                >
                  phone
                </span>
                065 700-3395
                </a>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <span className="image-vertical-align-helper"></span>
              <img src={moneyHouse} alt="Sell your CO<sub>2</sub> avoidance" className="co2-image d-none d-lg-inline" />
            </div>
          </div>
        </div>
      </div>

      {/* PPA */}
      <div className="container section-title-container pb-3 pb-lg-5 mb-0 mb-lg-5">
        <div className="row">
          <div className="col-lg-6 text-start">
          <span className="image-vertical-align-helper"></span>
            <img src={homePpa} alt="Power Purchaser Agreement" className="ppa-image d-none d-lg-inline" />
          </div>
          <div className="col-lg-6">
            <h2 className="ppa-title text-center text-lg-start">Power Purchase Agreement</h2>
            <p className="secondary-text-color">
              The PPA is a long-term agreement for the supply of renewable
              energy over an agreed period. 
              {/* <br></br>  */}
            </p>
            <p>
              <strong>Here's how it works:</strong>
            </p>
            <ol className="secondary-text-color">
              <li>
                Have a grid-tied solar system installed on your property by
                us for free
              </li>
              <li className="mt-3">
                Purchase all power produced by the system from us
                for the duration of the contract at a rate lower than
                that of Eskom
              </li>
              <li className="mt-3">
                At the end of the agreement, you'll be gifted the system -
                in other words, you'll have <b>free electricity</b> for the remaining lifetime of the system.
              </li>
            </ol>
            <div className="text-center">
              <button className="btn btn-primary mt-2 px-4 py-2">
                <a
                  href="/ppa"
                  className="text-light"
                  style={{ textDecoration: "none" }}
                >
                  Learn more
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* INSTALLATION PROCESS */}
      {/* <div className="bg-light pt-3 pb-5">
        <div className="mb-4">
          <Timeline />
        </div>
      </div> */}

      {/* Get Quote Modal Goes Here */}
      <div className="bg-light">
        <GetQuoteModal />
      </div>
    </>
  );
}
