import '../assets/css/accordian.scss';

export default function Accordian() {
  return (
    <div>
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h4 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              Photovoltaic System Diagram
            </button>
          </h4>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              This diagram shows all the elements of a standard photovoltaic
              system installed in a residential home.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h4 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Sun
            </button>
          </h4>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              The genesis of all our energy sources is the sun.* One thing we
              have in abundance here in South Africa, is sunlight. It's time to
              harness this resource to power our lives.
              <br></br>
              <br></br>
              * With the exception of nuclear energy.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h4 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Solar Panels
            </button>
          </h4>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              The solar panels directly convert sunlight into electricity - no
              detours. About 1m x 2m in size, they consist of glass, silicon and
              a sheet metal frame. They last for up to 30 years with a very
              small degradation.
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h4 className="accordion-header" id="headingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              Direct Current
            </button>
          </h4>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            In electricity, there is either direct current (DC), or alternate current (AC). Solar panels, like batteries, generate electricity as DC, while appliances use AC.


            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h4 className="accordion-header" id="headingFive">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              Inverter
            </button>
          </h4>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            The inverter is the heart of the system. It's primary job is the conversion from DC to AC, so that all your devices can be powered by the electricity coming from the panels.
            Another task for some inverters is the managing of a battery and providing backup energy, when the grid goes down.
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h4 className="accordion-header" id="headingSix">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              Battery
            </button>
          </h4>
          <div
            id="collapseSix"
            className="accordion-collapse collapse"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            As the sun doesn't shine by night and the grid goes down regularly, most of our customers choose to include a battery. It stores the surplus energy generated by the panels and releases it, when you need more than the sun provides.
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h4 className="accordion-header" id="headingSeven">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              Alternate Current
            </button>
          </h4>
          <div
            id="collapseSeven"
            className="accordion-collapse collapse"
            aria-labelledby="headingSeven"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            The electricity in the grid and coming from the sockets is alternate current (AC), therefore your devices and appliances are designed to work with AC.
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h4 className="accordion-header" id="headingEight">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
            >
              Electric Box
            </button>
          </h4>
          <div
            id="collapseEight"
            className="accordion-collapse collapse"
            aria-labelledby="headingEight"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            In the electric box, we connect the cables coming from the inverter to the main house line, which is connected to the grid. Here we also secure your system via RCDs and breakers.
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h4 className="accordion-header" id="headingNine">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNine"
              aria-expanded="false"
              aria-controls="collapseNine"
            >
              Meter
            </button>
          </h4>
          <div
            id="collapseNine"
            className="accordion-collapse collapse"
            aria-labelledby="headingNine"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            Solar systems require a bidirectional meter, that can count incoming electricity as well as outgoing. Your system exports surplus energy, which can be sold to the provider, if your municipality allows it.
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h4 className="accordion-header" id="headingTen">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTen"
              aria-expanded="false"
              aria-controls="collapseTen"
            >
              Utility Grid
            </button>
          </h4>
          <div
            id="collapseTen"
            className="accordion-collapse collapse"
            aria-labelledby="headingTen"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            The grid connects your house with the neighborhood, as well as with Eskom. During load shedding, the electricity is turned off by the provider. Your system then automatically switches to backup-mode and continues to power your house.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
