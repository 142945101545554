import React, { useState, useEffect } from "react";

import { useUserSelectionContext } from "../context/UserSelectionContext";

import PackageSpec from '../models/PackageSpec';

export default function SpecsCard({ title, description, specs, userData, destination }) {
  const [specsState, setSpecsState] = useState([]);
  const {userSelection, setUserSelection} = useUserSelectionContext();

  useEffect(() => {
    loadSpecs(specs);
  }, []);

  function loadSpecs(specs) {
    let arr = [];

    specs.forEach(spec => {
      const packageSpec = new PackageSpec(spec);
      arr.push(packageSpec);
    });

    setSpecsState(arr);
  }
  
  function handleOnClick() {
    // Set UserSelection data
    Object.assign(userSelection, userData);
    setUserSelection({...userSelection});

    destination();
  }

  return (
    <div className="card specs-card">
      <div className="card-body">
        <h5 className="card-title text-center p-2">{title}</h5>
        <p className="card-text">{description}</p>
      </div>
      <ul className="list-group list-group-flush text-start">
        { specsState.map((spec) => {
            return <li key={spec.id} className="list-group-item">
              <i className="bi bi-check-lg text-orange me-2" />
              {spec.spec}
            </li>
          })
        }
      </ul>
      <div className="card-body">
        <div className="me-5 ms-5 d-grid">
          <button className="btn btn-orange" onClick={handleOnClick}>Select</button>
        </div>
      </div>
    </div>
  );
}