import SEO from "../common/SEO";

import "../assets/css/benefits.scss";
import headerImage from "../assets/img/about-us.jpg";

export default function AboutUs() {
  return (
    <>
      {/* SEO */}
      <SEO
        title="Mantula Solar: About Us"
      />
      
      {/* HEADER */}
      <div
        className="hero hero-img-container small"
        style={{ backgroundImage: "url(" + headerImage + ")" }}
      >
        <div
          className="overlay color-background overlay d-flex align-items-center flex-column flex-lg-nowrap flex-sm-wrap text-center"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="container hero-container">
            <h1 className="hero-title animate fadeInLeft delay-600 text-center">
              About Us
            </h1>
            <h5 className="hero-subtitle animate fadeInLeft delay-400 text-center mt-3">
              The Photovoltaic System & everything you need to know
            </h5>
        </div>
      </div>
      </div>

      {/* OUT STORY */}
      <div className="container section-title-container">
        <h2 className="text-center">
          Our Story
        </h2>
        <hr />
        <p>
          Established in 2020, Mantula Solar is a young company recognizing the
          South-African need for a reliable power source. We are a team of South
          Africans and Germans providing tailor-made photovoltaic systems to
          customers all across the country. We strive to make clean energy
          affordable for everyone, so that we can run the country on pure
          sunshine.
        </p>
        
      </div>
      <div className="container section-title-container">
        <h3 className="lead">
          Our <b className="font-weight-bolder orange">Vision</b> is to
          neutralize carbon emissions in Africa by 2050
        </h3>
      </div>

      {/* OUR VALUES */}
      <div className="bg-light container section-title-container mt-5 values-container">
        <h2 className="text-center">
          Our Core Values
        </h2>

        <div className="flex">
          <div className="icon values-icon">
            <img
              alt="Responsibility"
              className="p-2"
              src={require("../assets/icons/handshake.jpg")}
            />
          </div>
          <div className="values-section">
            <hr />
            <div>
              <h3>Responsibility & Reliability.</h3>
              <p>
                At our company, we take pride in our quality solar systems
                and providing reliable maintenance and repair services when
                needed. We believe that a customer paying for a solar system
                needs to know that they can rely on their system and that we
                will be there to help them if any issues arise. Our high
                standards ensure that you will be happy with your purchase
                for years to come. We recognize and embrace our
                responsibilities to the environment, the community, the
                industry, our company and ourselves.
              </p>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="icon values-icon">
            <img
              alt="Integrity"
              className="p-2"
              src={require("../assets/icons/integrity.png")}
            ></img>
          </div>

          <div className="values-section">
            <hr />
            <div>
              <h3>Integrity & Passion</h3>
              <p>
                We believe that everyone should be welcome and feel included
                in our mission to fight for a more sustainable energy
                future. We strive to communicate openly and take initiative
                to grow together. We are passionate about our work and proud
                of what we do. There is no room for egos at Mantula Solar;
                instead, we focus on working together towards a common goal.
                We set ourselves apart from our competition by handpicking
                high quality components and engineering the best
                configurations for our customers.
              </p>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="icon values-icon">
            <img
              alt="Professionalism"
              className="p-2"
              src={require("../assets/icons/satisfaction.png")}
            ></img>
          </div>

          <div className="values-section">
            <hr />
            <div>
              <h3>Customer Commitment & Professionalism </h3>
              <p>
                Mantula Solar is a company with German engineering standards
                that responds quickly and effectively to customer needs. We
                take feedback, listen and learn from our customers to drive
                value and responsiveness. Our goal is to deliver the best
                possible experience, from initial contact through
                consultation and support. Customer satisfaction is our top
                priority.
              </p>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="icon values-icon">
            <img
              alt="Innovation"
              className="p-2"
              src={require("../assets/icons/innovation.png")}
            ></img>
          </div>
          <div className="values-section">
            <hr />
            <div>
              <h3>Innovation & Quality.</h3>
              <p>
                Mantula Solar is a company that prides itself on innovation
                and quality. We work at the forefront of solar energy,
                energy storage, and grid modernization, constantly striving
                to educate ourselves on the latest technologies so that we
                can deliver the most reliable and cost-effective forms of
                renewable energy generation. Our goal is to provide our
                clients with solutions that are both innovative and high
                quality, helping them reduce their carbon footprint while
                also saving money.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* OUR TEAM */}
      <div className="container section-title-container mt-5">
        <div className="text-center mb-5">
          <h2 className="text-center ">Our Team</h2>
          <h3 className="text-center">Dedicated to quality and service</h3>
        </div>
        
        <div className="row justify-content-center">
          <div className="col-lg-3 col-sm-12 m-3 order-1 order-sm-1">
              <div className="text-center">
                <img className="height-150 img-fluid rounded-circle mb-4 px-4" src={require("../assets/img/jonathan.jpg")} alt="Jonathan Bach" />
                <h5 className="fw-bolder">Jonathan Bach</h5>
                <div className="fst-italic text-muted">Founder &amp; German Engineer</div>
              </div>
          </div>
          <div className="col-lg-3 col-sm-12 m-3 mb-0 order-2 order-lg-2">
            <div className="text-center">
              <img className="height-150 img-fluid rounded-circle mb-4 px-4" src={require("../assets/img/hennie.jpg")} alt="Hennie Bester" />
              <h5 className="fw-bolder">Hennie Bester</h5>
              <div className="fst-italic text-muted">Project Manager</div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-12 m-3 mb-0 order-3 order-lg-2">
            <div className="text-center">
              <img className="height-150 img-fluid rounded-circle mb-4 px-4" src={require("../assets/img/charmaine.jpg")} alt="Hennie Bester" />
              <h5 className="fw-bolder">Charmaine Bester</h5>
              <div className="fst-italic text-muted">Bookkeeper</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-4 col-sm-12 m-3 mt-0 order-2 order-lg-3" >
            <div className="text-center">
              <img className="img-fluid px-4" src={require("../assets/img/alulex.jpg")} alt="Alulex" />
              <div className="fst-italic text-muted">Operations</div>
            </div>
          </div>
      </div>
    </>
  );
}
