import { useUserSelectionContext } from "../context/UserSelectionContext";

export default function BasicButton({
  text,
  buttonClass,
  btnClass = buttonClass ? buttonClass : "btn-primary",
  iconClass,
  userData,
  destination
}) {
  const { userSelection, setUserSelection } = useUserSelectionContext();

  function handleOnClick() {
    // Set UserSelection data
    Object.assign(userSelection, userData);
    setUserSelection({ ...userSelection });
    
    destination();
  }

  return (
      <button
        className={"btn " + btnClass + " mx-3 mt-1 text-light fs-5 px-3"}
        onClick={handleOnClick}
      >
      {
        iconClass ? <i className={"bi " + iconClass + " text-light me-2"} /> : <></>
      }
      {
        text
      }
    </button>
  );
}