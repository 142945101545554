import React, { useState, useEffect } from "react";

import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import navbar_logo from "../assets/img/navbar_logo.png";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import GetQuoteModal from "../common/GetQuoteModal";

export default function Header() {
  const [showWhyDropdown, setShowWhyDropdown] = useState(false);
  const [showProductsDropdown, setShowProductsDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  function handleWindowResize() {
    setWindowSize(getWindowSize());
  }

  function handleShowDropdown(fn) {
    if(windowSize.innerWidth > 991) {
      fn(true);
    }
  }
  
  function hideDropdown(fn) {
    if(windowSize.innerWidth > 991) {
      fn(false);
    }
  }

  function toggleDropdown(fn, state) {
    if(windowSize.innerWidth <= 991) {
      fn(!state);
    }
  }

  function handleShowModal() {
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  return (
    <>
      <Navbar expand="lg" className="headerBg nav-styles">
        <Container>
          <Navbar.Brand href="/">
            <img src={navbar_logo} alt="Mantula Solar" height="80" />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="ms-auto">
              <Nav.Link href="/" className="text-white">Home</Nav.Link>
              <Nav.Link href="/why-solar" className="text-white">Solar Explained</Nav.Link>
              <span
                onMouseEnter={() => handleShowDropdown(setShowWhyDropdown)} 
                onMouseLeave={() => hideDropdown(setShowWhyDropdown)}
                onClick={() => toggleDropdown(setShowWhyDropdown, showWhyDropdown) }
              >
                <NavDropdown title="Why Mantula" id="nav-dropdown"
                  renderMenuOnMount={true}
                  show={showWhyDropdown}
                >
                  <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
                  <NavDropdown.Item href="/benefits">Benefits</NavDropdown.Item>
                  <NavDropdown.Item href="/testimonials">Projects</NavDropdown.Item>
                </NavDropdown>
              </span>
              <span
                onMouseEnter={() => handleShowDropdown(setShowProductsDropdown)} 
                onMouseLeave={() => hideDropdown(setShowProductsDropdown)}
                onClick={() => toggleDropdown(setShowProductsDropdown, showProductsDropdown)}
              >
                <NavDropdown title="Products" id="nav-dropdown"
                  renderMenuOnMount={true}
                  show={showProductsDropdown}
                >
                  <NavDropdown.Item href="/residential">Residential</NavDropdown.Item>
                  <NavDropdown.Item href="/commercial">Commercial</NavDropdown.Item>
                  <NavDropdown.Item href="/ppa">Power Purchase Agreement</NavDropdown.Item>
                </NavDropdown>
              </span>
              <Nav.Link href="/contact" className="text-white">Contact Us</Nav.Link>
              <div>
                <Button onClick={handleShowModal} id="quote-button">Free Quote</Button>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header className="p-1 bg-light border-0" />
        <GetQuoteModal handleClose={handleCloseModal} />
        <Modal.Footer className="bg-light">
          <Button variant="secondary" onClick={handleCloseModal}>Maybe Later</Button>
        </Modal.Footer>
      </Modal>
  </>
  );
}