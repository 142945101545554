// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDzcWJGTqMsenyJSK6gWJD2JUUCc2A38Kw",
  authDomain: "mantula-fd72b.firebaseapp.com",
  projectId: "mantula-fd72b",
  storageBucket: "mantula-fd72b.appspot.com",
  messagingSenderId: "559810786911",
  appId: "1:559810786911:web:30c230cc585a4d78788dea",
  measurementId: "G-4BN63LN9S0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {
    db
}