import SEO from "../common/SEO";

import "../assets/css/main.scss";
import "../assets/css/residentialcommercial.css";

import background from '../assets/img/residential/commercialcopy.jpg';
import battery from "../assets/img/residential/battery.jpg";
import solar from '../assets/img/residential/solar2.jpg';
import hybrid from "../assets/img/residential/hybrid2.jpg";

import MessageUs from './MessageUs';
import Calculator from './Calculator';
import Enquiry from "./calculator/calculatorSteps/Enquiry";


export default function Commercial() {
  return (
    <>
      {/* SEO */}
      <SEO
        title="Mantula Solar: Commercial Solutions"
      />

      {/* HEADER */}
      <div
        className="hero hero-img-container small"
        style={{ backgroundImage: "url(" + background + ")", backgroundPosition: "center center" }}
      >
        <div
          className="overlay color-background overlay d-flex align-items-center flex-column flex-lg-nowrap flex-sm-wrap text-center"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="container hero-container">
            <h1 className="hero-title animate fadeInLeft delay-600 text-center">
              Commercial Solutions
            </h1>
            <h5 className="hero-subtitle animate fadeInLeft delay-400 text-center mt-3">
              Protect your business from power outages and lower that electricity bill
            </h5>
          </div>
        </div>
      </div>
      
      {/* CALCULATOR */}
      <div id="calculator" className="container section-title-container py-5">
        <h2 className="text-center mb-5">Each business is unique <br></br> Finding your optimal solution is our passion.</h2>

        <div className="bg-light rounded-4">
          <Calculator name="commercial" />
          
        </div>

        <div className="mt-5 text-center">
          <h3 id='rebate'>A rebate for your CO2 avoidance is <span className="orange">guaranteed</span> for all solar systems!</h3>
          <a 
            href="/benefits">
            <button className="btn btn-primary"><b>Learn more</b></button>
          </a>
        </div>
      </div>

      {/* BATTERY BACKUP */}
      <div className="bg-light" id="batteryBackup">
        <div className="container section-title-container py-5">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h2>Battery Backup</h2>

              <p className="bullet-heading fs-4">
                <i className="bi bi-plug"></i><b>No More Power Outages</b>
              </p>
              <h5 className="text-indent fw-normal">- Loadshedding becomes a non-issue</h5>
              <h5 className="text-indent fw-normal">- Decommission that old Generator</h5>
              <h5 className="text-indent fw-normal">- No more separate UPS required</h5>
              <h5 className="second-line-indented fw-normal">keeps your servers online</h5>
              <h5 className="text-indent fw-normal">- Solar ready - Plug and Play when you're ready</h5>
              <h5 className="text-indent fw-normal">- Perform Energy Arbitration on Time-Of-Use tariffs</h5>
              <h5 className="second-line-indented fw-normal">Charge during off-peak and discharge during peak</h5>



              <div className='links'>
                <div className='link review-button'>
                  <a href="/testimonials">
                    <button className="btn btn-primary"><b>Read Reviews</b></button>
                  </a>
                </div>
                <div className='link'>
                  <a href="/why-solar">
                    <button className="btn btn-secondary"><b>Learn About Solar</b></button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <img style={{objectFit: "cover", objectPosition: "center",overflow: "hidden",width:"100%"}} className="pic rounded-4 mt-lg-0 mt-4" src={battery} alt='Battery Backup System'/>
            </div>
          </div>
        </div>
      </div>

      {/* GRID-TIED SYSTEM */}
      <div id="gridTied">
        <div className="container section-title-container py-5">
          <div className="row flex-row-reverse">
            <div className="col-12 col-lg-6">
              <h2>Grid-Tied Solar</h2>

              <p className="bullet-heading fs-4">
                <i className="bi bi-sun"></i><b>How It Works + Fast ROI (return on investment)</b>
              </p>
              <h5 className="text-indent fw-normal">- Solar (photovoltaic) panels convert </h5>
              <h5 className="second-line-indented fw-normal">sunshine into direct current</h5>
              <h5 className="text-indent fw-normal">- Inverters convert direct current into usable electricity</h5>
              <h5 className="text-indent fw-normal">- ROI is fast due to inexpensive system </h5>
              <h5 className="second-line-indented fw-normal">and escalating utility prices</h5>

              

              {/* <p className="bullet-heading fs-4">
                <i className="bi bi-power"></i><b>Powerful Inverters</b>
              </p>
              <h5 className="text-indent fw-normal">- Able to convert 98% of direct current to alternating current</h5>
              <h5 className="text-indent fw-normal">- Takes care of partial shading (some panels are more shaded than others)</h5> */}

              <p className="bullet-heading fs-4">
                <i className="bi bi-battery"></i><b>Affordable Option</b>
              </p>
              <h5 className="text-indent fw-normal">- These systems don't require batteries</h5>
              <h5 className="text-indent fw-normal">- Inverters are be battery-ready for a phased approach</h5>

              <div className='links'>
                <div className='link review-button'>
                  <a href="/testimonials">
                    <button className="btn btn-primary"><b>Read Reviews</b></button>
                  </a>
                </div>
                <div className='link'>
                  <a href="/why-solar">
                    <button className="btn btn-secondary"><b>Learn About Solar</b></button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <img style={{objectFit: "cover", objectPosition: "center",overflow: "hidden",width:"100%"}} className="pic rounded-4 mt-lg-0 mt-4" src={solar} alt='Grid-Tied System'/>
            </div>
          </div>
        </div>
      </div>
      
      {/* HYBRID SOLAR SYSTEM */}
      <div className="bg-light" id="hybridSystem">
        <div className="container section-title-container py-5">
          <div className="row flex-row-reverse">
            <div className="col-12 col-lg-6">
              <img style={{objectFit: "cover", objectPosition: "center",overflow: "hidden",width:"100%"}} className="pic rounded-4 mt-lg-0 mt-4" src={hybrid} alt='Grid-Tied System'/>
            </div>
            <div className="col-12 col-lg-6">
              <h2>Hybrid Solar System</h2>

              <p className="bullet-heading fs-4">
                <i className="bi bi-check2-circle"></i><b>The Best of Both Worlds</b>
              </p>
              <h5 className="text-indent fw-normal">- All of the above combined in a single system</h5>
              <h5 className="text-indent fw-normal">- Charge batteries with solar and keep generating </h5>
              <h5 className="second-line-indented fw-normal">while the grid is out</h5>
              <h5 className="text-indent fw-normal">- Minimal grid reliance as batteries ensure an </h5>
              <h5 className="second-line-indented fw-normal">uninterrupted power supply</h5>
              <h5 className="text-indent fw-normal">- Saves money due to very little grid consumption</h5>
              <h5 className="text-indent fw-normal">- Take the strain off the national grid</h5>

              <p className="bullet-heading fs-4"><i className="bi bi-recycle"></i><b>Environmentally Friendly</b></p>
              <h5 className="text-indent fw-normal">- Green Power from your roof</h5>
              <h5 className="text-indent fw-normal">- Less Coal-generated Electricity</h5>
              <h5 className="text-indent fw-normal">- No Diesel required</h5>


            </div>

          </div>
        </div>
      </div>

      {/* <!-- form --> */}
      <div>
        {MessageUs()}
      </div>
    </>
  );
}