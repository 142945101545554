import SEO from "../common/SEO";

import "../assets/css/benefits.scss";

import background from "../assets/img/ppa/solarWorker.jpg";
import ppaProfitable from "../assets/img/ppa/profitable.png";
import ppaRiskless from "../assets/img/ppa/riskless.png";
import ppaFutureProof from "../assets/img/ppa/futureProof.png";
import ppaGiftedSolar from "../assets/img/ppa/ppaGift.jpg";
import ppaPriceGraph from "../assets/img/ppa/Rate.png";
import ppaSavingsGraph from "../assets/img/ppa/Savings.png";
import ecoImage from "../assets/img/ppa/ecoImage.png";
import compliance from "../assets/img/ppa/compliance.jpg";
import maintenance from "../assets/img/ppa/maintenance.jpg";

export default function Ppa() {
  return (
    <>
      {/* SEO */}
      <SEO
        title="Mantula Solar: Power Purchase Agreement"
      />

      {/* HEADER */}
      <div
        className="hero hero-img-container small"
        style={{ backgroundImage: "url(" + background + ")", backgroundPosition: "bottom center" }}
      >
        <div
          className="overlay color-background overlay d-flex align-items-center flex-column flex-lg-nowrap flex-sm-wrap text-center"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="container hero-container">
            <h1 className="hero-title animate fadeInLeft delay-600 text-center">
              Power Purchase Agreement
            </h1>
            <h5 className="hero-subtitle animate fadeInLeft delay-400 text-center mt-3">
              Long term solution for the supply of renewable electricity at no
              capital expenditure
            </h5>
          </div>
        </div>
      </div>

      {/* PPA EXPLAINED */}
      <div className="container section-title-container">
        <h2 className="text-center">PPA Explained</h2>
        <hr />
        <p>
          The Power Purchase Agreement (PPA) is a long-term agreement for the
          supply of renewable electricity over an agreed period (10 to 20
          years). You will have a grid-tied solar system installed on your
          property by Mantula Solar at no capital expenditure to you.
          Then, you purchase all the power produced by the system from us for
          the duration of the contract at a rate that is lower than Eskom’s
          and an escalation rate that is guaranteed. At the end of the agreed
          upon period, the system becomes yours.
        </p>
      </div>

      {/* BENEFITS */}
      <div className="bg-light">
        <div className="container section-title-container">
          <h2 className="text-center">
            Benefits of a PPA
          </h2>
          <h3 className="text-center">
            Obtain a grid-connected solar system for nothing down. Purchase energy at a fixed rate.
          </h3>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="card bg-light border-0 h-100">
                <img
                  src={ppaProfitable}
                  className="img-fluid mx-auto d-none d-lg-block"
                  style={{ width: "25%" }}
                  alt="Profitable"
                />
                <div className="mt-3 card-body rounded-4 p-3 mb-5 bg-white border h-100 ">
                  <h5 className="text-center fw-bold ppa-benefit-title">R0 - acquisition costs</h5>

                  <p className="text-center">
                    With the PPA solution, there are no upfront costs and no
                    maintenance costs for the PV system. At the end of the
                    contract period, we gift you the system to produce your own
                    energy for free.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="card border-0 bg-light h-100">
                <img
                  src={ppaRiskless}
                  className="img-fluid mx-auto d-none d-lg-block"
                  style={{ width: "26.7%" }}
                  alt=""
                />
                <div className="mt-3 card-body bg-white border rounded-4 p-3 mb-5 h-100 ">
                  <h5 className="text-center fw-bold ppa-benefit-title">
                    100% All-round hassle free
                  </h5>

                  <p className="text-center">
                    You will have a tailor-made grid-tied solar system installed
                    on your property. The maintenance and cleaning of the system
                    are included.. Everything is taken care of, so there is
                    nothing to worry about .
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="card bg-light border-0 h-100">
                <img
                  src={ppaFutureProof}
                  className="img-fluid mx-auto d-none d-lg-block"
                  style={{ width: "23%" }}
                  alt=""
                />
                <div className="mt-3 card-body bg-white border rounded-4 p-3 mb-5 bg-light h-100 ">
                  <h5 className="text-center fw-bold ppa-benefit-title">
                    Fixed annual escalation rate
                  </h5>

                  <p className="text-center">
                    The rising cost of electricity is a concern for many
                    consumers. The fixed price plans of the PPA helps to protect
                    you from dramatic increases in rates and generates notable
                    savings for you. Now you can budget long-term.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="card border-0 h-100 bg-light">
                <img
                  src={ecoImage}
                  className="img-fluid mx-auto d-none d-lg-block"
                  style={{ width: "31.5%" }}
                  alt=""
                />
                <div className="mt-3 card-body bg-white border rounded-4 p-3 mb-5 bg-light h-100 ">
                  <h5 className="text-center fw-bold ppa-benefit-title">
                    Less CO2 Emissions
                  </h5>

                  <p className="text-center">
                    The generation of electricity with photovoltaics does not
                    produce harmful greenhouse gases. Each square meter of
                    photovoltaic reduces CO2 emissions by about 100 kg per year
                    compared to coal-produced electricity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="gray py-5">
        <section data-aos="fade-up" className="mt-5">
          <div className="container">
            <div className="row g-0 bg-white rounded-4 nopadding py-3 shadow">
              <div className="col-lg-6 order-lg-1 position-relative">
                <span
                  className="background ms-3"
                  style={{ backgroundImage: `url(${ppaPriceGraph})`, width:'90%'}}
                ></span>
              </div>
              <div className="col-lg-6 p-4 p-md-10 order-lg-2 px-3">
                <h3 className="fw-normal">
                  Purchase your rooftop-generated Solar Energy from us. With
                  Mantula's fixed escalation rate you'll save a lot of money.
                </h3>
                <p className="mt-4">
                  Energy prices have increased dramatically over the past few
                  year, and they have no signs of stopping. Conservative
                  estimations result in a 15% annual increase in energy prices. 
                  The PPA enables you to purchase locally generated solar energy
                  from Mantula Solar at rate lower than Eskom's. Over the period of the 
                  agreement your savings will be substantial.
                  <br />
                  <br />
                </p>
                <li className="list-group-item mt-3">
                  <div className="d-flex flex-row">
                    <p className="">
                      The graph shows our fixed and guaranteed tariff in
                      comparison to Eskom’s expected annual price increase.
                    </p>
                  </div>
                </li>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 mt-3" data-aos="fade-up">
          <div className="container">
            <div className="row g-0 bg-white nopadding rounded-4 py-3 shadow">
              <div className="col-lg-6 order-lg-2 position-relative">
              <span
                  className="background"
                  style={{ backgroundImage: `url(${ppaSavingsGraph})`, width:'95%'}}
                ></span>
              </div>
              <div className="col-lg-6 p-4 p-md-10 order-lg-1 px-3">
                <h3 className="fw-normal">
                  {" "}
                  Generate long-term savings. Reduce your profit-killing energy
                  bills.
                </h3>
                <p className="mt-4">
                  Saving long-term means investing in business critical assets. Instead of 
                  using hard-earned profits to pay for electricity, rather invest that money 
                  in growing your business.
                  Mantula's PPA offers a unique solution to your rising
                  electricity bills. With no capital expenditure upfront and no
                  solar running cost, you will have a lower energy bill
                  from day one. 
                  <br />
                  <br />
                </p>
                <div className="row">
                  <p>
                    The graph shows your estimated accumulated savings
                    throughout the contract.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 mt-3 mb-5" data-aos="fade-up">
          <div className="container">
            <div className="row g-0 bg-white nopadding rounded-4 shadow" style={{overflow: 'hidden'}}>
              <div className="col-lg-6 order-lg-1 position-relative">
                <span
                  className="background"
                  style={{ backgroundImage: `url(${compliance})` }}
                ></span>
              </div>
              <div className="col-lg-6 p-4 p-md-10 order-lg-2 p-3">
              <h3 className="fw-normal">
                  {" "}
                  High Quality Equipment and Compliant Installation
                </h3>
                <p className="mt-4">
                    We are committed to delivering the agreed-upon amount of electricity to you, our
                    valued customer. You only pay for the electricity that we reliably provide. As
                    your financial partner in this project, our goal is to ensure that your system operates
                    seamlessly and consistently performs at its peak capacity. Achieving this requires
                    the use of top-quality equipment known for its reliability.
                  <br />
                  <br />
                </p>
                <div className="row">
                  <p>
                    Safety and compliance to regulations are pillars on which we build our company and reputation.
                    We don't succumb to the temptation of shortcuts, even if it means, that projects
                    get delayed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 mt-3 mb-5" data-aos="fade-up">
          <div className="container">
            <div className="row g-0 bg-white nopadding rounded-4 shadow" style={{overflow: 'hidden'}}>
              <div className="col-lg-6 order-lg-2 position-relative">
                <span
                  className="background"
                  style={{ backgroundImage: `url(${maintenance})` }}
                ></span>
              </div>
              <div className="col-lg-6 p-4 p-md-10 order-lg-1 p-3">
              <h3 className="fw-normal">
                  {" "}
                  Maintenance and Monitoring included
                </h3>
                <p className="mt-4">
                    With this product, we try to have as little impact into the daily lives of our customers as possible.
                    We want our customers to benefit of the solar system without any financial setbacks or worries such as 
                    continued safety, or reduced performance. This is the reason why we include all maintenance such as 
                  <br />
                  <br />
                </p>
                <ul className="border-0 p-0">
                  <li className="list-group-item mt-4">
                    <div className="d-flex flex-row">
                      <i
                        className="p-2 bi bi-droplet"
                        style={{ fontSize: "1.3rem" }}
                      ></i>
                      <h5 className="p-2 fw-normal">Cleaning of panels</h5>
                    </div>
                  </li>
                  <li className="list-group-item mt-1">
                    <div className="d-flex flex-row">
                      <i
                        className="p-2 bi bi-plug"
                        style={{ fontSize: "1.3rem" }}
                      ></i>
                      <h5 className="p-2 fw-normal">Inspection of all electrical connections</h5>
                    </div>
                  </li>
                  <li className="list-group-item mt-1">
                    <div className="d-flex flex-row">
                      <i
                        className="p-2 bi bi-battery-half"
                        style={{ fontSize: "1.3rem" }}
                      ></i>
                      <h5 className="p-2 fw-normal">Maintenance of inverter and batteries</h5>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 mt-3 mb-5" data-aos="fade-up">
          <div className="container">
            <div className="row g-0 bg-white nopadding rounded-4 shadow" style={{overflow: 'hidden'}}>
              <div className="col-lg-6 order-lg-1 position-relative">
                <span
                  className="background"
                  style={{ backgroundImage: `url(${ppaGiftedSolar})` }}
                ></span>
              </div>
              <div className="col-lg-6 p-4 p-md-10 order-lg-2 p-3">
                <h3 className="fw-normal">
                  {" "}
                  Upon expiry of contract...
                </h3>
                <p className="mt-4">
                  ...you will be given three options:
                </p>


                <ul className="border-0 p-0">
                  <li className="list-group-item mt-4">
                    <div className="d-flex flex-row">
                      <i
                        className="p-2 bi bi-1-circle"
                        style={{ fontSize: "1.3rem" }}
                      ></i>
                      <h5 className="p-2 fw-normal">Extend the contract period</h5>
                    </div>
                      <p className="mx-5">Continue to enjoy a worry-free system with maintenance included at a rate lower than Eskom</p>
                  </li>
                  <li className="list-group-item mt-1">
                    <div className="d-flex flex-row">
                      <i
                        className="p-2 bi bi-2-circle"
                        style={{ fontSize: "1.3rem" }}
                      ></i>
                      <h5 className="p-2 fw-normal">Take over ownership of the system</h5>
                    </div>
                    <p className="mx-5">For a symbolic value of R1, the system is yours.</p>
                  </li>
                  <li className="list-group-item mt-1">
                    <div className="d-flex flex-row">
                      <i
                        className="p-2 bi bi-3-circle"
                        style={{ fontSize: "1.3rem" }}
                      ></i>
                      <h5 className="p-2 fw-normal">Ask us to take our equipment and leave</h5>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="container">
        <div className="text-center py-5">
          <h2>Contact us to learn more about the PPA</h2>
          <h4 className="fw-normal">See if your property is available for the agreement</h4>

          <div className="d-flex justify-content-center mt-5">
            <button className="btn btn-primary btn-block col-5 py-2">
              <a href="/contact" className="text-light fw-bold">
                Contact Us
              </a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
