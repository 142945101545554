import SEO from "../common/SEO";

import "../assets/css/main.scss";
import "../assets/css/residentialcommercial.css";

import battery from "../assets/img/residential/battery.jpg";
import hybrid from "../assets/img/residential/hybrid2.jpg";
import background from '../assets/img/residential/residentialcopy.jpg'
import MessageUs from './MessageUs';

import Calculator from './Calculator';

export default function Residential() {
  return (
    <>
      {/* SEO */}
      <SEO
        title="Mantula Solar: Residential Solutions"
      />

      {/* HEADER */}
      <div
        className="hero hero-img-container small"
        style={{ backgroundImage: "url(" + background + ")", backgroundPosition: "center center" }}
      >
        <div
          className="overlay color-background overlay d-flex align-items-center flex-column flex-lg-nowrap flex-sm-wrap text-center"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="container hero-container">
            <h1 className="hero-title animate fadeInLeft delay-600 text-center">
              Residential Solutions
            </h1>
            <h5 className="hero-subtitle animate fadeInLeft delay-400 text-center mt-3">
              Protect your home from power outages and lower that electricity bill
            </h5>
          </div>
        </div>
      </div>

      {/* CALCULATOR */}
      <div id="calculator" className="container section-title-container py-5">
        <h2 className="text-center mb-5">Find out which option is best for your home</h2>

        <div className="bg-light rounded-4">
          <Calculator name="residential" />
        </div>

        <div className="mt-5 text-center">
          <h3 id='rebate'>A rebate for your CO2 avoidance is <span className="orange">guaranteed</span> for all solar systems!</h3>
          <a 
            href="/benefits#benCards">
            <button className="btn btn-primary"><b>Learn more</b></button>
          </a>
        </div>
      </div>

      {/* BATTERY BACKUP */}
      <div className="bg-light"id="batteryBackup">
        <div className="container section-title-container py-5">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h2>Battery Backup System</h2>
              
              <p className="bullet-heading fs-4">
                <i className="bi bi-plug"></i><b>No More Power Outages or <br />Generator Reliance</b>
              </p>

              <h5 className="text-indent fw-normal">- Loadshedding becomes a non-issue</h5>
              <h5 className="text-indent fw-normal">- No longer have to maintain an old generator</h5>
              <h5 className="text-indent fw-normal">- Have confidence in a UPS (uninterrupted power supply) powering your home</h5>

              <p className="bullet-heading fs-4"><i className="bi bi-recycle"></i><b>Environmentally Friendly</b></p>

              <h5 className="text-indent fw-normal">- Takes care of all electrical necessities</h5>
              <h5 className="text-indent fw-normal">- No fuel required (powered by electricity)</h5>
              <h5 className="text-indent fw-normal">- Pairs well with time-of-use utility plan</h5>

              <div className='links'>
                <div className='link review-button'>
                  <a href="/testimonials">
                    <button className="btn btn-primary"><b>Read Reviews</b></button>
                  </a>
                </div>
                <div className='link'>
                  <a href="/why-solar">
                    <button className="btn btn-secondary"><b>Learn About Solar</b></button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <img style={{objectFit: "cover", objectPosition: "center",overflow: "hidden",width:"100%"}} className="pic rounded-4 mt-lg-0 mt-4" src={battery} alt='Battery Backup System'/>
            </div>
          </div>
        </div> 
      </div>

      {/* HYBRID SOLAR SYSTEM */}
      <div id="hybridSystem">
        <div className="container title-section-container py-5">
          <div className="row flex-row-reverse">
            <div className="col-12 col-lg-6">
              <h2 className="system-title text-start">Hybrid Solar System</h2>

              <p className="bullet-heading fs-4 text-start"><i className="bi bi-check2-circle"></i><b>The Best of Both Worlds</b></p>

              <h5 className='text-indent fw-normal'>- Combines a stable power supply with electricity from solar panels</h5>
              <h5 className='text-indent fw-normal'>- Minimal grid reliance as battery backup is powered by solar</h5>
              <h5 className='text-indent fw-normal'>- Saves money due to very little grid consumption</h5>

              <div className='links mb-4'>
                <div className='link review-button'>
                  <a href="/testimonials">
                    <button className="btn btn-primary"><b>Read Reviews</b></button>
                  </a>
                </div>
                <div className='link'>
                  <a href="/why-solar">
                    <button className="btn btn-secondary"><b>Learn About Solar</b></button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <img style={{objectFit: "cover", objectPosition: "center",overflow: "hidden",width:"100%"}} className="pic rounded-4" src={hybrid} alt='Hybrid Solar System'/>
            </div>
          </div>
        </div>
      </div>

      {/* MESSAGE US */}
      <div className="bg-light">
      { MessageUs() }
      </div>
    </>
  )
}
