export default function footer() {
  return (
      <footer className="footer">
        <div className="container-fluid">
          <div className="row justify-content-md-center pt-4 pb-2">
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="col-md-4 mt-4 mt-md-0">
              

                <img style={{maxHeight:"30px",width:"auto", marginRight:"20px"}}
                  className="card-img-top "
                  src={require("../assets/img/bni-white.png")}
                  alt="Responsibility and Reliability"
                >
                </img>
   

              <img style={{maxHeight:"30px",width:"auto"}}
                className="card-img-top"
                src={require("../assets/img/pvgreencard.png")}
                alt="Responsibility and Reliability"
              ></img>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="col-md-4 mt-3 mt-md-0"
            >
              <a href="https://goo.gl/maps/pjCBp55D89waZcjSA" target="_blank">
              <i
                className="bi bi-google m-3"
                style={{ color: "white", fontSize: "2rem" }}
              ></i>
              </a>
              <a href="https://www.linkedin.com/company/mantulasolar" target="_blank">
              <i
                className="bi bi-linkedin m-3"
                style={{ color: "white", fontSize: "2rem" }}
              ></i>
              </a>
              <a href="https://www.instagram.com/mantula.solar/" target="_blank">
              <i
                className="bi bi-instagram m-3"
                style={{ color: "white", fontSize: "2rem" }}
              ></i>
              </a>
              <a href="https://www.facebook.com/mantula.solar" target="_blank">
              <i
                className="bi bi-facebook m-3"
                style={{ color: "white", fontSize: "2rem" }}
              ></i>
              </a>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="col-md-4 mt-2 mt-md-0 mb-4 mb-md-0 align-items-center"
            >
              <p className="mb-0">&copy; 2024 Mantula Solar</p>
            </div>
          </div>
        </div>
      </footer>
  );
}
