import SEO from "../common/SEO";

import testimonialHeroImg from "../assets/img/testimonials_hero_banner.jpg";

import project1 from "../assets/img/projects/testimonials_project_1.jpg";
import project1a from "../assets/img/projects/testimonials_project_1a.jpg";

import project9 from "../assets/img/projects/testimonials_project_9.jpg";
import project9a from "../assets/img/projects/testimonials_project_9a.jpg";

import project10 from "../assets/img/projects/testimonials_project_10.jpg";
import project10a from "../assets/img/projects/testimonials_project_10a.jpg";

import project11 from "../assets/img/projects/testimonials_project_11.jpg";
import project11a from "../assets/img/projects/testimonials_project_11a.jpg";

import project12 from "../assets/img/projects/testimonials_project_12.jpg";
import project12a from "../assets/img/projects/testimonials_project_12a.jpg";

import project13 from "../assets/img/projects/testimonials_project_13.jpg";
import project13a from "../assets/img/projects/testimonials_project_13a.jpg";

import project14 from "../assets/img/projects/testimonials_project_14.jpg";
import project14a from "../assets/img/projects/testimonials_project_14a.jpg";

import project15 from "../assets/img/projects/testimonials_project_15.jpg";
import project15a from "../assets/img/projects/testimonials_project_15a.jpg";

import project16 from "../assets/img/projects/testimonials_project_16.jpg";
import project16a from "../assets/img/projects/testimonials_project_16a.jpg";

import project17 from "../assets/img/projects/testimonials_project_17.jpg";
import project17a from "../assets/img/projects/testimonials_project_17a.jpg";

import TestimonialCard from "./TestimonialCard";
import TestimonialReview from "./TestimonialReview";

export default function Testimonials() {
  return (
    <>
      {/* SEO */}
      <SEO
        title="Mantula Solar: Projects"
      />

      {/* HEADER */}
      <div
        className="hero hero-img-container small"
        style={{ backgroundImage: "url(" + testimonialHeroImg + ")" }}
      >
        <div
          className="overlay color-background overlay d-flex align-items-center flex-column flex-lg-nowrap flex-sm-wrap text-center"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="container hero-container">
            <h1 className="hero-title animate fadeInLeft delay-600 text-center">
              Projects & Testimonials
            </h1>
            <h5 className="hero-subtitle animate fadeInLeft delay-400 text-center mt-3">
              Learn about our past projects, and our happy customers
            </h5>
          </div>
        </div>
      </div>

      <div className="gray">
        <div className="container section-title-container">
        <h2 className="text-center">Our past projects</h2>
        <h3 className="text-center">
            Learn about systems we've installed in the past and their happy
            customers!
        </h3>
        <div className="container mt-5 text-center py-5">
          <TestimonialCard
            projectNum="17"
            image1={project17}
            image2={project17a}
            title="Symrise Specialities Pet Food in Paarl"
            description="This factory in Paarl was suffering under load-shedding, since their main appliance is a large
            grinder motor, which could not be run by the diesel generator. Combining the need of uninterrupted power supply
            with the striving to be more ecological, we designed a hybrid solar system for them. This system consists of
            a mighty 500kW battery inverter to run the motor, paired with 600kWh of lithium batteries. A 110kW grid-tied
            solar inverter feeds green energy into the mix. The generator is integrated to recharge batteries if ever necessary."
            peakPower="77"
            batteryCapacity="600"
            annualGeneration="119 000"
            emissionsPrevented="115"
            quote={null}
          />           
          <TestimonialCard
            projectNum="16"
            image1={project16}
            image2={project16a}
            title="St. Matthews Church in Tableview"
            description="This project held a unique distinction - the client relied on Eskom power rather than Municipal power. Traditionally, solar systems in this scenario are subject to extensive registration processes with Eskom, leading to prolonged application times and higher, less favourable tariffs.
Our solution? We crafted an off-grid system while keeping Eskom in the wings, ready to act as a fallback. 
Our client is delighted to retain their existing Eskom tariff and only engage them as a backup when the battery charge drops. This innovation is now a standard offering to all our commercial clients supplied directly by Eskom. What's more, we've even extended this design to include a generator as a secondary backup."
            peakPower="36"
            batteryCapacity="61"
            annualGeneration="61 000"
            emissionsPrevented="59"
            quote={null}
          />           
          <TestimonialCard
            projectNum="15"
            image1={project15}
            image2={project15a}
            title="Senalala Safari Lodge in the Greater Kruger National Park"
            description="We're thrilled to share our project at Senalala, in the heart of the Klaserie Private Nature Reserve! Alulex and Mantula Solar successfully installed two 50kW inverters, combined to deliver a whopping 100kW of power, complemented by 99kWp of panels strategically positioned for optimal sunlight exposure.
              But that's not all! To ensure uninterrupted energy supply, we've incorporated 200kWh of lithium batteries, storing daytime energy for nighttime use. Plus, for extra peace of mind, a diesel generator stands ready to kick in if needed."
            peakPower="99"
            batteryCapacity="200"
            annualGeneration="164 000"
            emissionsPrevented="146"
            quote={null}
          /> 
          <TestimonialCard
            projectNum="14"
            image1={project14}
            image2={project14a}
            title="Tulela Safari Lodge in the Klaserie Private Nature Reserve"
            description="In the heart of Greater Kruger National Park, Tulela Safari Lodge, surrounded by wildlife, lost some of its magic due to load-shedding, leaving the hum of a diesel generator. They chose sustainability, partnering with Alulex and Mantula Solar. Phase one succeeded: 180 solar panels, a 150kW inverter, and 200kWh lithium battery in a custom container. Now, Tulela aims to maximize efficiency, so guests can once again enjoy lions' roars and hyenas' laughter under the African night sky."
            peakPower="99"
            batteryCapacity="400"
            annualGeneration="163 000"
            emissionsPrevented="146"
            quote={null}
          /> 
          <TestimonialCard
            projectNum="13"
            image1={project13}
            image2={project13a}
            title="A Wine Farm in Stellenbosch"
            description="This beautiful wine farm with its Cape Dutch style buildings requires a reliable source of power, primarily for its wine making facilities. Due to our design, which included extensive trenching and cabling, we were able to provide both the farmhouse, as well as the wine cellar with an uninterrupted power supply. Now our client doesn’t have to worry about load shedding interrupting the rare bottling dates or any other step of the wine making process."
            peakPower="9.9"
            batteryCapacity="20.4"
            annualGeneration="14167"
            emissionsPrevented="13.7"
            quote={null}
          /> 
          <TestimonialCard
            projectNum="12"
            image1={project12}
            image2={project12a}
            title="A Newly Built Home in Pniël"
            description="Since power outages are very rare in Europe, constant load shedding poses a major inconvenience for this newly immigrated client. Fortunately, our system provides backup power from day one of their new life."
            peakPower="2.73"
            batteryCapacity="5.1"
            annualGeneration="4261"
            emissionsPrevented="4.1"
            quote={null}
          /> 
          <TestimonialCard
            projectNum="11"
            image1={project11}
            image2={project11a}
            title="A Heritage Site in Cape Town"
            description="This luxury Guest House in Tamboerskloof was built by the first Mayor of Cape Town as his personal residence. Now they don’t have to worry about loadshedding and even generate excess solar power that they push back into the grid. By doing this they relieve some stress on the very strained grid."
            peakPower="27.74"
            batteryCapacity="56"
            annualGeneration="43669"
            emissionsPrevented="42.4"
            quote={null}
          /> 
          <TestimonialCard
            projectNum="10"
            image1={project10}
            image2={project10a}
            title="A luxury Guest Lodge near Mossel Bay"
            description="This pristine Game Farm on the Garden Route already had solar panels installed. To cut diesel consumption during loadshedding and to reduce the electricity bill by avoiding to buy electricity during peak tariff periods we installed a containerised battery energy storage system. All energy sources being solar, Eskom and diesel generator are controlled by the central battery inverter. In a second stage, we added approximately 100kWp of solar power to the existing array."
            peakPower="330"
            batteryCapacity="900"
            annualGeneration="501000"
            emissionsPrevented="486"
            quote={null}
          /> 
          <TestimonialCard
            projectNum="9"
            image1={project9}
            image2={project9a}
            title="A Guest Lodge in Knysna"
            description="This hybrid solar system was especially designed to meet the requirements of the tourism sector. Neither the owners nor the guests of this lodge in Knysna have to worry about sitting in the dark."
            peakPower="5.52"
            batteryCapacity="10.2"
            annualGeneration="9323"
            emissionsPrevented="9.0"
            quote={null}
          />
          {/* <TestimonialCard
            projectNum="8"
            image1={project8}
            image2={null}
            title="A House in Val de Vie Estate"
            description="This client already had a grid tied solar system installed. Grid-tied solar systems shut down, when the grid is out. This is a safety feature to protect maintenance workers on the grid, but it also means lights out during load shedding. This small system keeps many appliances in the house up and running."
            peakPower="0"
            batteryCapacity="5.12"
            annualGeneration="0"
            emissionsPrevented="0"
            quote={null}
          /> */}

          {/* <TestimonialCard
            projectNum="7"
            image1={project7}
            image2={project7a}
            title="A Residential Building along the Dolphin Coast"
            description="To reduce his dependence on Eskom as well as his utility bill, this customer chose a hybrid system, which combines lithium batteries with solar panels. All the excess electricity during the day is stored in his battery to provide power at night. "
            peakPower="4.05"
            batteryCapacity="5.12"
            annualGeneration="7138"
            emissionsPrevented="6.9"
            quote="Perfect and professional work. We can fully recommend this company."
          /> */}

          <TestimonialCard
            projectNum="1"
            image1={project1}
            image2={project1a}
            title="A Residential Building in Somerset West"
            description="We installed a hybrid solar system for our client at his
            private home in Somerset West. Not only does he save money
            on his electric bill, but he also helps the environment. A
            hybrid solar system combines both rooftop solar panels and a
            battery storage unit. This allows our client to store energy
            generated during the daytime for use at night or during load
            shedding."
            peakPower="5.52"
            batteryCapacity="5.1"
            annualGeneration="9815"
            emissionsPrevented="9.1"
            quote="This was such a great journey from the idea to realization. I will refer you to my friends!"
          />
{/* 
          <div id="battery">
            <TestimonialCard
              projectNum="2"
              image1={project2}
              image2={null}
              title="A Private Farm around Stellenbosch"
              description="Unfortunately a solar system on the roof of this farm wasn’t
              an option - due to shading. But a battery can be a great way
              to provide backup power during Load Shedding. By installing
              a 5 kWh battery, the owner of this house can now charge the
              battery from the grid and store the power until needed. In
              case of a power outage he is now able to to run critical
              appliances and devices in his home."
              peakPower="0"
              batteryCapacity="5.1"
              annualGeneration="0"
              emissionsPrevented="0"
              quote="You did a great job. This looks just like an installation
              within German Engineering standards."
            />
          </div>

          <TestimonialCard
            projectNum="3"
            image1={project3}
            image2={project3a}
            title="A Residential Building in Greytown"
            description="The owner of this building was tired of unreliable energy sources and looking for a way to go off the grid completely in the future. Due to his system he is now able to save money and have peace of mind knowing that he'll always have a reliable source of energy."
            peakPower="5.92"
            batteryCapacity="10.2"
            annualGeneration="9992"
            emissionsPrevented="9.3"
            quote={null}
          />

          <TestimonialCard
            projectNum="4"
            image1={project4}
            image2={project4a}
            title="An Office along the Dolphin Coast"
            description="Webservers need to stay online - even during load shedding. The inverter acts as a line-interactive UPS (Uninterrupted Power Supply), keeping the servers, computers and even some aircons powered, when the grid goes down."
            peakPower="6.37"
            batteryCapacity="20.4"
            annualGeneration="11330"
            emissionsPrevented="10.5"
            quote="With only 14 panels and a battery we can cover all our electricity needs - from the coffee machine, computers to the air conditioner."
          />

          <TestimonialCard
            projectNum="5"
            image1={project5}
            image2={project5a}
            title="A Farmhouse in Kwa-Zulu Natal"
            description="Because of the long supply line, the owner of the remote farmhouse paid a high connection fee to the electricity supplier. The system takes the house completely off the power grid. A relatively large battery was required, because of the many rainy days in Natal's summer."
            peakPower="5.18"
            batteryCapacity="15.3"
            annualGeneration="9060"
            emissionsPrevented="8.2"
            quote="I'm always surprised at how well the system works. Even with all the rain and fog in the last two weeks."
          />

          <TestimonialCard
            projectNum="6"
            image1={project6}
            image2={project6a}
            title="A Residential Building along the Dolphin Coast"
            description="The system charges the battery during the day, when there is surplus solar energy and discharges during the night. This way, the power bill is dramatically reduced and load shedding is no longer an issue."
            peakPower="5.2"
            batteryCapacity="10.2"
            annualGeneration="9150"
            emissionsPrevented="8.5"
            quote="During the storm last night, the neighbors were complaining about a power outage. We never even noticed the power go out!"
          />
           */}
        </div>
        </div>
      </div>

      <div className="bg-dark py-5">
        <h1 className="text-center text-white">Reviews</h1>
        <br />

        <div className="container justify-content-center">
          <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="col">
              <TestimonialReview
                review="Great professional experience with our solar instalation with Mantula Solar. We would definitely recommend them… from a well thought out scoping to a quick, painless installation. We were very impressed with the level of skills and experience of this team. Would recommend them for anyone looking to install a solar system in their home. The company further also partnered with a German NGO which paid us for 5 years of carbon savings from our system. The funds reflected within 2 weeks of installation, an added bonus!"
                author="Ivan Roux"
              />
            </div>

            <div className="col">
              <TestimonialReview
                review="Thanks to Mantula Umfulana's office in Salt Rock now is
                solar powered! We are very satisfied with Mantula's work.
                From the first quote up until the installation of the solar panels the team around Mantula were very
                professional, competent and reliable. With only 14 panels
                and a battery we can cover all our electricity needs -
                from the coffee mashine, computers to the air conditioner.
                5 star recommendation."
                author="Tobias Garstka"
              />
            </div>

            <div className="col">
              <TestimonialReview
                review="Jonathan from Mantula Solar met with us personally and he
                knows his product and is passionate about what he does. We
                had an inverter but the batteries were not lasting. We bought our new
                batteries from him and it works so well and we gave us
                options of a way forward in smaller projects to get
                completely off the grid. Looking forward to getting it all
                done with Mantula Solar."
                author="Lee-Ann Wijtenburg"
              />
            </div>
            <div></div>
            <div className="btn btn-primary btn-lg mt-4">
              <b>
                <a
                  href="https://goo.gl/maps/pjCBp55D89waZcjSA"
                  className="text-light"
                  style={{ textDecoration: "none" }}
                >
                  Read more!
                </a>
              </b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
