import React, { useEffect, useState } from "react";
import { Controls } from "react-decision-tree-flow";

import StepWrapper from "../common/StepWrapper";
import NextButton from "../common/NextButton";

export default function PeopleSlider({ title, steps, backButton }) {
  const [people, setPeople] = useState(steps[0].value);
  const [stepValues, setStepValues] = useState(null);
  const [morePeople, setMorePeople] = useState("");

  useEffect(() => {
    // Get all values for the range slider
    let sValues = [];

    steps.forEach(step => {
      sValues.push(step.value);
    });

    setStepValues(sValues);
  }, []);

  function onRangeChange(num) {
    if(num > steps[steps.length - 2].value) {
      setPeople(steps[steps.length - 1].value);
    } else {
      setPeople(num);
      setMorePeople("");
    }
  }

  function saveUserSelection() {
    if(morePeople === "") {
      return { householdSize: people === steps[steps.length - 1].value ? "6+ People" : people + " People" };
    } else {
      return { householdSize: morePeople + " People" };
    }
  }

  return (
    <Controls>
      {({ destinations, back }) => (
        <StepWrapper
          title={title}
          backButton={backButton}
          back={back}
        >
          <div className="row mx-3 justify-content-md-center">
            <div className="col-12 col-lg-6">
              <input type="range" className="form-range orange-range"
                min={steps[0].value}
                max={steps[steps.length - 1].value}
                step={steps[1].value - steps[0].value}
                value={people}
                onChange={(e) => onRangeChange(parseInt(e.target.value))}
              />
              { people < steps[steps.length - 1].value ? <p className="text-start">{people - 1 + " or " + people + " People"}</p> :
                <div className="col-12 col-sm-7 col-lg-8 col-xl-6 mt-2">
                  <div className="input-group">
                    <span className="input-group-text"><i className="bi bi-person-fill text-dark" /></span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter number of people"
                      value={morePeople}
                      onChange={(e) => setMorePeople(e.target.value)}
                    />
                  </div>
                </div>
              }
              <NextButton
                userData={saveUserSelection()}
                destination={() => {
                  let index = stepValues.indexOf(people);
                  
                  steps[index].nextStep === "quote" ?
                    destinations[steps[index].nextStep](steps[index].quote)
                  :
                    destinations[steps[index].nextStep]()
                }}
              />
            </div>
          </div>
        </StepWrapper>
      )}
    </Controls>
  );
}