import { Controls } from "react-decision-tree-flow";

import StepWrapper from "../common/StepWrapper";
import BasicButton from "./BasicButton";

export default function BasicButtons({ title, buttons, backButton}) {
  return (
    <Controls>
      {({ destinations, back }) => (
        <StepWrapper
          title={title}
          backButton={backButton}
          back={back}
        >
          <div className="row d-flex justify-content-center">
            <div>
            {
              buttons.map((button) => {
                return <span key={buttons.indexOf(button)}>
                  <BasicButton
                    buttonClass={button.buttonClass}
                    iconClass={button.iconClass}
                    text={button.text}
                    userData={{ optionText: button.text }}
                    destination={() =>
                      button.nextStep && button.nextStep === "quote" ?
                        destinations[button.nextStep](button.quote)
                      :
                        destinations[button.nextStep]()
                    }
                  />
                </span>
              })
            }
            </div>
          </div>
        </StepWrapper>
      )}
    </Controls>
  );
}
