import React, { useEffect, useState } from "react";

const ReviewStar = () => {
  return (
    <span
      className="material-icons"
      style={{ fontSize: "25px", color: "#ffd700" }}
    >
      star_rate
    </span>
  );
};

export default function TestimonialReview(
  props
) {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div
      className="card bg-secondary text-white h-100 border-none"
      style={{ cursor: "default" }}
    >
      <div className="card-body">
        <ReviewStar />
        <ReviewStar />
        <ReviewStar />
        <ReviewStar />
        <ReviewStar />

        <div>
          {/* {isMobile ? <p>{props.review}</p> : <h5>{props.review}</h5>} */}
          <p>{props.review}</p>
          <p>
            <b>{props.author}</b>
          </p>
        </div>
      </div>
    </div>
  );
}
