import React, { useEffect, useState } from "react";
import "bootstrap/js/dist/carousel";

export default function TestimonialCard(
  props
  // projectNum,
  // image1,
  // image2 = false,
  // title,
  // description,
  // peakPower,
  // batteryCapacity,
  // annualGeneration,
  // emissionsPrevented,
  // quote
) {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;


  return (
    <div
      className="card bg-light border-0 rounded-4 text-dark mb-5 shadow"
      style={{ cursor: "default" }}
      data-aos="fade-up"
    >
      <div className="row g-0 py-0">
        <div className="col-lg-6">
          {props.image2 ? (
            <div
              id={"project" + props.projectNum}
              className="carousel slide rounded-start"
              data-bs-ride="carousel"
              style={{ height: "100%", overflow: "hidden" }}
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target={"#project" + props.projectNum}
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target={"#project" + props.projectNum}
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
              </div>

              <div className="carousel-inner" style={{ height: "100%" }}>
                <div
                  className="carousel-item active"
                  style={{ height: "100%" }}
                >
                  {isMobile ? (
                    <img
                      src={props.image1}
                      className="d-block w-100"
                      alt="House"
                      style={{
                        borderTopLeftRadius: "1rem",
                        borderTopRightRadius: "1rem",
                        objectFit: "cover",
                        objectPosition: "center",
                        overflow: "hidden",
                        height: "100%",
                        width: "auto",
                      }}
                    />
                  ) : (
                    <img
                      src={props.image1}
                      className="d-block w-100"
                      alt="House"
                      style={{
                        borderBottomLeftRadius: "1rem",
                        borderTopLeftRadius: "1rem",
                        objectFit: "cover",
                        objectPosition: "center",
                        overflow: "hidden",
                        height: "100%",
                        width: "auto",
                      }}
                    />
                  )}
                </div>
                <div className="carousel-item" style={{ height: "100%" }}>
                  {isMobile ? (
                    <img
                      alt="House"
                      src={props.image2}
                      className="d-block w-100"
                      style={{
                        borderTopLeftRadius: "1rem",
                        borderTopRightRadius: "1rem",
                        objectFit: "cover",
                        objectPosition: "center",
                        overflow: "hidden",
                        height: "100%",
                        width: "auto",
                      }}
                    />
                  ) : (
                    <img
                      alt="House"
                      src={props.image2}
                      className="d-block w-100"
                      style={{
                        borderBottomLeftRadius: "1rem",
                        borderTopLeftRadius: "1rem",
                        objectFit: "cover",
                        objectPosition: "center",
                        overflow: "hidden",
                        height: "100%",
                        width: "auto",
                      }}
                    />
                  )}
                </div>
              </div>

              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target={"#project" + props.projectNum}
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>

              <button
                className="carousel-control-next"
                type="button"
                data-bs-target={"#project" + props.projectNum}
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          ) : (
            <span>
              {isMobile ? (
                <img
                  alt="House"
                  src={props.image1}
                  className="d-block w-100"
                  style={{
                    borderTopLeftRadius: "1rem",
                    borderTopRightRadius: "1rem",
                    objectFit: "cover",
                    objectPosition: "center",
                    overflow: "hidden",
                    height: "100%",
                    width: "auto",
                  }}
                />
              ) : (
                <img
                  alt="House"
                  src={props.image1}
                  className="d-block w-100"
                  style={{
                    borderBottomLeftRadius: "1rem",
                    borderTopLeftRadius: "1rem",
                    objectFit: "cover",
                    objectPosition: "center",
                    overflow: "hidden",
                    height: "100%",
                    width: "auto",
                  }}
                />
              )}
            </span>
          )}
        </div>

        <div className="col-lg-6 d-flex flex-column">
          <div className="card-body py-4 px-4 d-flex flex-column">
            <h3 className="card-title fs-3" style={{ cursor: "text" }}>
              {props.title}
            </h3>
            <hr className="my-2" />
            <p className="card-text fs-6 mb-0 text-start mt-2" style={{ cursor: "text" }}>
              {props.description}
            </p>

            <div className="flex-grow-1"></div>

            <div className="row pb-0">
              <div className="col-6 col-md-3 pe-0 mb-2 mb-md-0">
                <div
                  className="card border border rounded-4 bg-light text-dark h-100"
                  style={{ cursor: "default" }}
                >
                  <div className="container">
                    <span
                      className="material-icons my-3 text-primary"
                      style={{ fontSize: "30px" }}
                    >
                      light_mode
                    </span>
                  </div>
                  <h6
                    className="card-title mt-0 mb-2 mx-1"
                    style={{ cursor: "text" }}
                  >
                    Peak Power Generation
                  </h6>
                  <div className="card-body m-0 p-0"></div>
                  <div className="card-footer mt-2 mb-0">
                    <p className="mb-0">
                      {props.peakPower} kW
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-3 pe-md-0 mb-2 mb-md-0">
                <div
                  className="card border rounded-4 bg-light text-dark h-100"
                  style={{ cursor: "default" }}
                >
                  <div className="container">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="my-3 text-primary"
                      viewBox="0 0 576 512"
                    >
                      <path d="M448 320H96V192H448V320zM0 176C0 131.8 35.82 96 80 96H464C508.2 96 544 131.8 544 176V192C561.7 192 576 206.3 576 224V288C576 305.7 561.7 320 544 320V336C544 380.2 508.2 416 464 416H80C35.82 416 0 380.2 0 336V176zM80 160C71.16 160 64 167.2 64 176V336C64 344.8 71.16 352 80 352H464C472.8 352 480 344.8 480 336V176C480 167.2 472.8 160 464 160H80z" />
                    </svg>
                  </div>
                  <h6
                    className="card-title mt-0 mb-0 mx-1"
                    style={{ cursor: "text" }}
                  >
                    Battery<br />Capacity
                  </h6>
                  <div className="card-body m-0 p-0"></div>
                  <div className="card-footer mt-2 mb-0">
                    <p className="mb-0">
                      {props.batteryCapacity} kWh
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-3 pe-0">
                <div
                  className="card border border rounded-4 bg-light text-dark h-100"
                  style={{ cursor: "default" }}
                >
                  <div className="container">
                    <span
                      className="material-icons my-3 text-primary"
                      style={{ fontSize: "30px" }}
                    >
                      electric_bolt
                    </span>
                  </div>
                  <h6
                    className="card-title mt-0 mx-1"
                    style={{ cursor: "text" }}
                  >
                    Annual Generation Capacity
                  </h6>
                  <div className="card-body m-0 p-0"></div>
                  <div className="card-footer mt-2 mb-0">
                    <p className="mb-0">
                      {props.annualGeneration} kWh
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div
                  className="card border border rounded-4 bg-light text-dark h-100"
                  style={{ cursor: "default" }}
                >
                  <div className="container">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="my-3 text-primary"
                      viewBox="0 0 24 24"
                    >
                      <path d="M15.49 9.63c-.18-2.79-1.31-5.51-3.43-7.63-2.14 2.14-3.32 4.86-3.55 7.63 1.28.68 2.46 1.56 3.49 2.63 1.03-1.06 2.21-1.94 3.49-2.63zm-3.44-4.44c.63 1.03 1.07 2.18 1.3 3.38-.47.3-.91.63-1.34.98-.42-.34-.87-.67-1.33-.97.25-1.2.71-2.35 1.37-3.39zM12 15.45c-.82-1.25-1.86-2.34-3.06-3.2-.13-.09-.27-.16-.4-.26.13.09.27.17.39.25C6.98 10.83 4.59 10 2 10c0 5.32 3.36 9.82 8.03 11.49.63.23 1.29.4 1.97.51.68-.12 1.33-.29 1.97-.51C18.64 19.82 22 15.32 22 10c-4.18 0-7.85 2.17-10 5.45zm1.32 4.15c-.44.15-.88.27-1.33.37-.44-.09-.87-.21-1.28-.36-3.29-1.18-5.7-3.99-6.45-7.35 1.1.26 2.15.71 3.12 1.33l-.02.01c.13.09.26.18.39.25l.07.04c.99.72 1.84 1.61 2.51 2.65L12 19.1l1.67-2.55c.69-1.05 1.55-1.95 2.53-2.66l.07-.05c.09-.05.18-.11.27-.17l-.01-.02c.98-.65 2.07-1.13 3.21-1.4-.75 3.37-3.15 6.18-6.42 7.35zm-4.33-7.32c-.02-.01-.04-.03-.05-.04 0 0 .01 0 .01.01.01.01.02.02.04.03z" />
                    </svg>
                  </div>

                  <h6
                    className="card-title mt-0 mb-0 mx-1"
                    style={{ cursor: "text" }}
                  >
                    Annual CO2 Emissions Prevented
                  </h6>
                  <div className="card-body m-0 p-0"></div>
                  <div className="card-footer mt-2 mb-0">
                    <p className="mb-0">
                      {props.emissionsPrevented} tons
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props.quote ? (
            <div className="card-footer">
              <p className="mb-1 p-2">
                “{props.quote}”
              </p>
            </div>
          ) : (
            <> </>
          )}
        </div>
      </div>
    </div>
  );
}
