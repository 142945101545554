import React, { useEffect, useRef } from "react";
import Parse from 'html-react-parser';

import BackButton from "./BackButton";

export default function StepWrapper({ title, children, backButton, back }) {
  const calcRef = useRef(null)

  useEffect(() => {
    const element = document.getElementById("calculator");
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset;
    
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="pb-2" ref={calcRef}>
      <p className="text-center fs-2 p-4 mb-4">{Parse(title)}</p>
      { children }
      <div className="float-left">
        { backButton ?
          <BackButton destination={back} />
          :
          <></>
        }
      </div>
    </div>
  );
}
