import {
    addDoc,
    collection,
} from "firebase/firestore"

import { db } from "../firebase/firebase"

class MessageService {
    constructor() {
        this.collection = 'mail';
        this.recipient = "info@mantula.co.za";
    }

    async submitMessage(message) {
        const collectionReference = collection(db, this.collection);
        await addDoc(collectionReference, {
            to: this.recipient,
            message:{
              subject: "Website Contact Form: " + message.name,
              text: "You have received a new message from your website contact form.\n\n"+"Here are the details:\n\nName: " + message.name + "\n\nEmail: " + message.email + "\n\nPhone: "+ message.phone +" \n\nMessage:\n" + message.message,
            },
            date:new Date(),
        });
        
    }

    async submitEnquiry(enquiry) {
        const collectionReference = collection(db, this.collection);
        await addDoc(collectionReference, {
            to: this.recipient,
            message:{
              subject: "Website Enquiry Submission: " + enquiry.firstName + " " + enquiry.lastName,
              text: "You have received a new enquiry from your website quote calculator.\n\n"+"Here are the details:\n\nName: " + enquiry.firstName + " " + enquiry.lastName + "\n\nEmail: " + enquiry.email + "\n\nPhone: "+ enquiry.phone +"\n\nAddress: " + enquiry.address +"\n\nSystem Details: " + enquiry.systemDetails + "\n\nQuote Number: " + enquiry.quote + "\n\nAdditional Notes:\n" + enquiry.additionalNotes,
            },
            date:new Date(),
        });
        
    }
}

const service = new MessageService();

export default service;
