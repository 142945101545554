import { Controls } from "react-decision-tree-flow";

import StepWrapper from "../common/StepWrapper";
import QuoteContent from "../calculatorSteps/QuoteContent";

export default function Quote({ quotes, backButton }) {
  return (
    <Controls>
      {({ destinations, back, data }) => (
        <StepWrapper
          title={'<span className="fs-1">We have a <span className="text-orange">quote</span> for you!</span>'}
          backButton={backButton}
          back={back}
        >
          <QuoteContent
            quote={quotes[data - 1]}
          />
        </StepWrapper>
      )}
    </Controls>
  );
}