import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

import solarTiles from "../assets/img/whySolar/solarTiles.jpg";
import "../assets/css/benefits.scss";

import SEO from "../common/SEO";
import Accordian from "../common/Accordian";
import cell from "../assets/img/whySolar/cell.jpg";
import einstein from "../assets/img/whySolar/einstein.jpg";
import inverter from "../assets/img/whySolar/inverter.jpg";
import wallbox from "../assets/img/whySolar/wallbox.png";
import multiMono from "../assets/img/whySolar/multi-mono.jpg";
import chemistry from "../assets/img/whySolar/n-dope.png";
import curveBat from "../assets/img/whySolar/curveBatScreenshot.png";
import curveNoBat from "../assets/img/whySolar/curveNoBatScreenshot.png";

import { ReactComponent as Svg } from "../assets/img/whySolar/whySolarDiagram.svg";


export default function WhySolar() {
  const [withBattery, setWithBattery] = useState(false);

  return (
    <>
      {/* SEO */}
      <SEO
        title="Mantula Solar: Solar Explained"
      />
      
      {/* HEADER */}
      <div
        className="hero hero-img-container small"
        style={{ backgroundImage: "url(" + solarTiles + ")", backgroundPosition: "bottom center" }}
      >
        <div
          className="overlay color-background overlay d-flex align-items-center flex-column flex-lg-nowrap flex-sm-wrap text-center"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="container hero-container">
            <h1 className="hero-title animate fadeInLeft delay-600 text-center">
              Solar Explained
            </h1>
            <h5 className="hero-subtitle animate fadeInLeft delay-400 text-center mt-3">
              Photovoltaics and how we make it work for us
            </h5>
        </div>
      </div>
      </div>

      {/* PHOTOVOLTAIC SYSTEM */}
      <div className="container section-title-container">
        <h2 className="text-center">
          Photovoltaic-System
        </h2>
        <h3 className="text-center">Click on the markers to learn more</h3>

        <div className="row mt-5">
          <div className="col-12 col-lg-7">
            <Svg style={{width:"100%"}}></Svg>
          </div>
          <div className="col-12 col-lg-5">
            <Accordian />
          </div>
        </div>
      </div>

      {/* EXPLANATION */}
      <div className="parallax parallax-gradient row mx-0 g-0">
        <div className="d-flex justify-content-center">
          <div className="container explanation-container">
            {/* How photovoltaic were discovered */}
            <div className="explanation-section">
              <h2>How photovoltaics were discovered</h2>

              <div className="row d-flex justify-content-center">
                <div className="col-md-4 row-sm px-4">
                  <img
                    className="img-fluid"
                    src={einstein}
                    alt="Albert Einstein"
                  />
                  <figcaption className="figure-caption">
                    Albert Einstein
                  </figcaption>
                </div>
              <div className="col-md-8 row-sm">
                <p>
                  As early as 1839, the French researcher Alexandre Becquerel
                  observed that certain materials conduct electricity when
                  exposed to light. However, the "photoelectric effect" was so
                  weak that it was often doubted and attributed to measurement
                  errors.
                </p>
                <p>
                  It was not until the beginning of the 20th century that Albert
                  Einstein provided a plausible explanation. He received the
                  Nobel Prize in 1921 for his work on light quantum theory. He
                  was able to explain why certain substances, e.g. silicon,
                  change their charging properties under the influence of light.
                </p>
                <p>
                  Finally, in 1954, the first silicon solar cell was produced.
                  It had an efficiency of less than 6 percent. A few years
                  later, the first technical applications followed, e.g. for
                  light meters and space flight.
                </p>
                <p>
                  For a long time, photovoltaics was considered complicated,
                  ineffective and expensive. Today, it is simple, clean,
                  versatile energy that is also the cheapest. It plays a key
                  role in achieving all climate goals.
                </p>
                </div>
              </div>
            </div>

            {/* How photovoltaics work */}
            <div className="explanation-section">
              <h2>How photovoltaics work</h2>
              <div className="row d-flex justify-content-center pb-0">
                <div className="col-md-8 row-sm">
                <p>
                  A solar cell consists of a thin layer of silicon that is only
                  0.3 mm thick. Silicon is pure sand. Four electrons move on the
                  outer electron shell of the atom. Eight are optimal for a
                  solid crystal formation and so the atom shares two electrons
                  each with four neighbouring atoms.
                </p>
                <p>
                  Phosphorus atoms are mixed into the upper, very thin silicon
                  layer. Phosphorus has five electrons on the outer shell, four
                  of which form a bond with the surrounding silicon electrons.
                  The fifth electron remains bond-free. It is called the n-layer
                  (n as in negative) because of the excess electrons that are
                  not bound in the lattice.
                </p>
                <p>
                  In the lower layer, which is a hundred times thicker, it is
                  exactly the opposite. Boron is added there, which only has
                  three electrons on the outer shell. In the solid silicon
                  lattice, however, four electrons would be optimal for stable
                  crystal formation. Since one is missing, it is therefore
                  called the p-layer (p as in positive).
                </p>
                <p>
                  In the border region between boron- and phosphorus-doped
                  material, something remarkable now happens: Excess electrons
                  from the n-layer migrate into the p-layer to the boron atoms.
                  This charges the silicon electrically. As electrons migrate
                  from the top, the layer becomes positive. The lower part,
                  where the electrons migrate, becomes negatively charged.
                </p>
                <p>
                  If the silicon plate is irradiated with light, the rays
                  penetrate through the very thin upper layer into the
                  transition area. There, the electrons that have attached
                  themselves to the boron atoms are blasted out again and pulled
                  upwards by the positive charge of the upper layer. At the same
                  moment, a lack of electrons becomes noticeable at the bottom.
                </p>
                <p>
                  All you have to do now is connect the top and bottom with a
                  conductor and you have a current flow.
                </p>
                <p>
                  The solar cell is a small power station. It is not powered by
                  fossil or nuclear fuels, but solely by the sun's rays. A 15x15
                  cm cell produces a voltage of 0.5 volts. To increase the
                  voltage, a module has several cells connected in series. In
                  most photovoltaic modules, 60 cells are connected in series to
                  produce a module voltage of around 30V and outputs of around
                  270Wp up to 400Wp.
                </p>
                </div>
                <div className="col-md-4 row-sm">
                  <img
                    className="img-fluid"
                    src={chemistry}
                    alt="Silicon n-Layer"
                  />
                  <figcaption className="figure-caption">
                    Phosphorus-doped silicon lattice <br />
                    <a
                      href="https://creativecommons.org/licenses/by-sa/3.0/"
                      title="CC: CC BY-SA 3.0; Creator: Markus A. Henning"
                    >
                      Licence
                    </a>
                  </figcaption>
                </div>
              </div>
            </div>
            
            {/* How solar is built */}
            <div className="explanation-section">
              <h2>How a solar cell is built</h2>
              <div className="row d-flex justify-content-center">
                <div className="col-md-4">
                  <img
                    className="img-fluid"
                    src={cell}
                    alt="Solar Cell"
                  />
                  <figcaption className="figure-caption ">
                    Solar cell <br />
                    Source: Solaranlage.de
                  </figcaption>
                </div>
                <div className="col-md-8">
                  <p>
                    The bottom of the cell is an aluminium foil that serves as a
                    metal contact. Above this is a layer of silicon, which is
                    usually only 0.3 mm thick and corresponds to the width of a
                    hair. This layer is doped with boron atoms. On top of this is an
                    even thinner layer of silicon, which is doped with phosphorus
                    atoms. This layer is only 1/1000 mm thick. <br />
                    The blue shimmering anti-reflective layer above it is supposed
                    to protect against environmental influences and light
                    reflections. Above this are the conductive pathways, which must
                    be as thin as possible because light is supposed to penetrate
                    into the cell between them. These "fingers" lead to the metal
                    contact that receives the electrons.
                  </p>
                </div>
              </div>
            </div>

            {/* We we need an inverter */}
            <div className="explanation-section">
              <h2>Why we need an inverter</h2>
              <div className="row d-flex justify-content-center">
                <div className="col-md-4">
                    <img
                      className="img-fluid"
                      src={multiMono}
                      alt="Left: Poly; Right: Mono"
                    />
                    <figcaption className="figure-caption">
                    </figcaption>
                </div>
                <div className="col-md-8">
                    <p>
                      There is a wide range of PV cells and the research in this area
                      is going strong. Only two types of cells are commonly used in
                      photovoltaic systems, both based on silicon. This is due to
                      their longevity, efficiency and component materials. It is
                      important, that cells aren't made up of any toxic or rare
                      substances, as this would counter their purpose in being an
                      ecological alternative to conventional electricity sources.
                      Mono- and polycrystalline cells differ in appearance and
                      characteristic.
                    </p>
                    <p>
                      Monomodules have a higher efficiency in low light than
                      polymodules. Therefore, monomodules have a slightly higher
                      output compared to polymodules with the same size. <br />
                      Visually, the cells differ only slightly. Polymodules tend to
                      look blue, while monomodules look black or dark blue.
                      Monomodules can be recognised by their strongly rounded corners
                      and polycells by their marbled cell structure. <br />
                      Polycells are a bit cheaper due to the less complicated
                      manufacturing procedure. <br />
                      The temperature coefficient is lower for polymer modules than
                      for mono modules. This means, that polymodules lose less power
                      than monomodules as module temperatures rise.
                    </p>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-md-4 order-lg-2">
                  <img
                    className="img-fluid "
                    src={inverter}
                    alt="Solar Cell"
                  />
                  <figcaption className="figure-caption">
                    Inverter <br />
                    Source: enerix.de
                  </figcaption>
                </div>
                <div className="col-md-8">
                  <p>
                    The inverter is the heart and the brain of every PV system.
                    Therefore, we only install high quality inverters, that have
                    proven their worth on an international level. Modern inverters
                    carry out an array of tasks:
                  </p>
                  <h4 className="mt-4 mb-2"><span className="text-primary">1.</span> Inverting</h4>
                  <p>
                    Photovoltaic panels produce direct current. Since most
                    electrical devices work with alternating current, the direct
                    current must be converted into standard household 230V/50Hz
                    alternating current (AC). <br />
                    On top, they make sure, that the system always runs in the
                    right gear to maximize the output power. Partial shading
                    becomes a problem of the past, since some inverters monitor
                    the system on panel level. <br />
                    Today's inverters work very efficiently. They convert 98
                    percent of the direct current into alternating current. The
                    remaining 2 percent becomes heat, which is why the inverters
                    need temperature management. <br />
                  </p>
                  <h4 className="mt-4 mb-2"><span className="text-primary">2.</span> Battery Management</h4>
                  <p>
                    In addition to conventional inverters, more and more hybrid
                    inverters are being used. A hybrid inverter is the
                    combination of a photovoltaic inverter and a battery inverter
                    and has the advantage that the direct current generated by the
                    solar system can be stored directly in the battery. The energy
                    is only converted into AC when it is drawn from the battery.{" "}
                    <br />
                    This saves space, is much easier to install and also has fewer
                    conversion losses.
                  </p>
                  <h4 className="mt-4 mb-2"><span className="text-primary">3.</span> Backup Power</h4>
                  <p>
                    Load shedding is an issue and it looks like, that won't change
                    any time soon. Modern inverters can power your home, when the
                    grid is down. This works as long as the sun is shining and
                    your battery is charged.
                  </p>
                  <h4 className="mt-4 mb-2"><span className="text-primary">4.</span> Power your Geyser</h4>
                  <p>
                    Nearly every household in South Africa has an electric geyser.
                    This is by far the largest consumer in the house. It only
                    makes sense to power the geyser with PV-generated electricity.{" "}
                    <br />
                    We therefore offer to include a smart geyser controller, that
                    powers your standard heating element, no changes to the geyser
                    required. This controller communicates with the inverter to
                    push excess electricity into the geyser. This maximizes your
                    consumption of self-generated electricity, instead of gifting
                    it into the grid. <br />
                  </p>
                  <h4 className="mt-4 mb-2"><span className="text-primary">5.</span> Charge your car</h4>
                  <p>
                    The future of mobility is electricity. A prerequisite to
                    driving an electric car is the possibility to charge your car
                    at home. We offer smart wall-boxes, that communicate with the
                    inverter to use excess PV-electricity to charge your car.
                  </p>
                </div>
              </div>
            </div>

            {/* Electric vehicle charging */}
            <div className="explanation-section">
              <h2>Electric Vehicle Charging</h2>

              <div className="row d-flex justify-content-center row-reverse">
                <div className="col-md-4">
                  <img
                    className="img-fluid"
                    src={wallbox}
                    alt="Electric Vehicle Charger"
                  />
                  <figcaption className="figure-caption">
                    Electric Vehicle Charger <br />
                    Source: wallbox.com
                  </figcaption>
                </div>
                <div className="col-md-8">
                  <p>
                    Let's take a look into the future of mobility here in South
                    Africa. In Europe and North America electric vehicles are
                    becoming as frequent as conventional ICE (Internal
                    Combustion Engine) cars. With rising fuel prices and an
                    increase in vehicle availability, you might consider
                    electric vehicles as an alternative for your next car. The
                    network of publicly accessible charging stations is growing,
                    but you will want to be able to charge your car at home,
                    because that's where it will be standing idle most of the
                    time.
                  </p>
                  <p>
                    Wall charging stations are the most convenient, fastest and
                    safest way to charge your e-car at home. Your car needs
                    direct current (DC), but alternating current (AC) comes from
                    the distribution network. The current must be converted by
                    your on-board rectifier (AC charging) or by your charging
                    station (DC charging). <br />
                    "Can't I just plug my e-car into a wall outlet at home?"{" "}
                    <br />
                    The answer: You can, but charging takes a long time and
                    connecting cables can overheat. Therefore, it's not
                    recommended
                  </p>
                  <p>Charging stations can be configured in two modes:</p>
                  <ul>
                    <li>
                      Free Charging: <br />
                      The charger will only use the excess solar energy to
                      charge the car, reducing the electricity cost to zero.
                    </li>
                    <li>
                      Fast Charging: <br />
                      Get the car ready for the next ride in the shortest time
                      possible, irrespective of solar electricity production
                    </li>
                  </ul>

                  <p>
                    As a company, you could offer your clients as well as your
                    employees the service of charging their cars during working
                    hours. <br />
                    As a hotel, guest house or lodge, you could attract more
                    guests by advertising the possibility to charge their cars,
                    either during lunch, or over night.
                  </p>
                  <p>
                    Billing is made simple via an app, that monitors the
                    consumption of each charging session. You will always keep
                    control over who charges their car, by using NFC-cards that
                    unlock the charger.
                  </p>
                </div>
              </div>
            </div>

            {/* Batteries. Yes, or no? */}
            <div className="explanation-section">
              <h2>Batteries. Yes, or No?</h2>
              <div className="d-flex justify-content-center">
                <div className="mt-2">
                  <div className="text-center mb-2">
                    <button
                      onClick={(e) => {
                        setWithBattery(true);
                      }}
                      type="button"
                      className="btn btn-primary m-3"
                    >
                      {" "}
                      <b>With Battery</b>
                    </button>

                    <button
                      onClick={(e) => {
                        setWithBattery(false);
                      }}
                      type="button"
                      className="btn btn-primary m-3"
                    >
                      {" "}
                      <b>Without Battery</b>
                    </button>
                  </div>
                  <div className="text-center bg-white py-4" style={{borderRadius: "1em"}}>
                      <p>
                        Consumption Curve (green) and Generation Curve (Orange)
                      </p>
                      {withBattery ? (
                        <img src={curveBat} alt="" style={{ width: "65%" }}></img>
                      ) : (
                        <img
                          src={curveNoBat}
                          alt=""
                          style={{ width: "65%" }}
                        ></img>
                      )}
                  </div>

                  <p className="mt-4">
                    Electricity storage systems make photovoltaic systems really
                    effective and boost the consumption of self-generated
                    pv-energy. <br />
                    The figures above show the electricity consumption and
                    generation over one day. Usually, the consumption peaks in the
                    morning, when the geyser is fired and in the evening during
                    meal preparation, washing, etc. The sun however, shines
                    strongest during the daytime when the consumption drops.{" "}
                    <br />
                    Without a battery, all the surplus electricity generated
                    during day time will be fed into the grid. As of yet, many
                    municipalities don't have an feed-in scheme yet. This means,
                    that the system would export electricity to the public without
                    rewarding you. <br />
                    A battery stores the surplus electricity, so you can use it
                    when you need it, regardless of the sun. <br />
                    Our aim is to maximize your self-consumption while minimizing
                    the amount of fed-in electricity.
                  </p>
                  <p>
                    So what about load shedding? A solar system rids you of the
                    necessity of a Diesel generator. As soon as the grid goes
                    down, the system will switch into backup mode. The house is
                    disconnected from the grid to keep electricians safe, that may
                    be maintaining it. The electricity from the panels is used
                    directly in your house as long as the sun is shining. Next,
                    the batteries are used to keep your lights on and your
                    computer running. <br />
                    This will only work with batteries installed. <br />
                    It should be said, that backup supply is no given in
                    PV-systems in general.
                  </p>
                  <p>
                    We exclusively use high quality lithium ion batteries, that
                    are reliable and safe. There is no need for refilling the
                    batteries and there are no toxic fumes released. <br />
                    In the last decade, electricity storage systems have undergone
                    rapid development. The prices have dropped and are continuing
                    to drop as the quality increases. Research is still going
                    strong in this field. Nonetheless, batteries are still the
                    most expensive part in your system. Therefore it should be
                    said, that systems without batteries may make sense in your
                    individual circumstance. For instance, if you work from home
                    and have your power consumption peak during day time. <br />
                    We design your system battery-ready, so that you can upgrade
                    at any later point in time.
                  </p>
                  <div className="card border-0 p-3 mt-5" style={{cursor: "default"}}>
                    <h4 className="text-primary">TLDR (Too long, didn't read)</h4>
                    <ul className="text-start mx-auto mt-2 tldr">
                      <li>
                        Batteries make sense to maximize the consumption of your
                        PV-electricity
                      </li>
                      <li>
                        Backup power only works with batteries
                      </li>
                      <li>
                        Batteries are expensive and can be added at any later
                        point in time
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
