import SEO from "../common/SEO";

import headerImage from "../assets/img/benefitsHomePage.jpg";
import savings from "../assets/img/savings.jpg";
import loadshedding from "../assets/img/loadShed.jpg";
import carbon from "../assets/img/load-shedding.jpg";

import "../assets/css/benefits.scss";

export default function Benefits() {
  return (
    <>
      {/* SEO */}
      <SEO
        title="Mantula Solar: Benefits"
      />

      {/* HEADER */}
      <div
        className="hero hero-img-container small"
        style={{ backgroundImage: "url(" + headerImage + ")" }}
      >
        <div
          className="overlay color-background overlay d-flex align-items-center flex-column flex-lg-nowrap flex-sm-wrap text-center"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="container hero-container">
            <h1 className="hero-title animate fadeInLeft delay-600 text-center">
              Benefits
            </h1>
            <h5 className="hero-subtitle animate fadeInLeft delay-400 text-center mt-3">
              Switch to a Solar System, get a break from the energy bill
            </h5>
          </div>
        </div>
      </div>

      {/* FREE YOURSELF FROM */}
      <div className="container section-title-container">
        {/* <h2 className="text-center">Free yourself from:</h2> */}
        <div className="d-flex align-items-center">
          <div className="row">
            <div className="py-3 col">
              <div className="row">
                <h3 className="text-primary fs-2 fw-normal">Load Shedding</h3>
                <hr  />
                <div className="card border-0">
                  <p className="text-start">
                  Load shedding can disrupt your daily activities and cause significant inconvenience. 
                  With a solar power system, you can ensure a reliable energy supply even during power outages. 
                  Enjoy uninterrupted power and peace of mind, knowing that your home or business will stay 
                  powered regardless of grid stability. Our advanced solar solutions guarantee that you remain 
                  productive and comfortable, no matter the external power situation.
                  </p>
                </div>
              </div>
              <div className="row">
                <h3 className="text-primary fs-2 fw-normal">Rising Electricity Bills</h3>
                <hr  />
                <div className="card border-0">
                  <p className="text-start">
                  High electricity bills can strain your budget and create financial uncertainty. 
                  By switching to solar power, you can significantly reduce your monthly electricity costs. 
                  Harness the power of the sun to enjoy long-term savings and protection against rising energy 
                  prices. With our solar systems, you not only lower your utility bills but also contribute 
                  to a greener, more sustainable future, making your investment beneficial both financially 
                  and environmentally.
                  </p>
                </div>
              </div>
              <div className="row">
                <h3 className="text-primary fs-2 fw-normal">Recoup Installation Costs</h3>
                <hr  />
                <div className="card border-0">
                  <p className="text-start">
                  Investing in a solar system is a smart financial decision. 
                  Not only will you save on electricity bills, but you can also recoup your installation 
                  costs over time. Through our exclusive partnership with a dedicated NGO, we offer unique 
                  incentives that can significantly offset your initial investment. These incentives are 
                  designed to make solar power more accessible and affordable, allowing you to watch your 
                  savings grow as your system pays for itself. With our solar solutions, you’re making a wise 
                  choice that benefits both your wallet and the planet.
                  </p>
                </div>
              </div>
              <div className="row">
                <h3 className="text-primary fs-2 fw-normal">Rental Solutions</h3>
                <hr  />
                <div className="card border-0">
                  <p className="text-start">
                  We understand that the upfront cost of a solar system can be a barrier for many. 
                  That's why we offer a flexible 60-month rental option, allowing you to pay off your 
                  system comfortably on a month-to-month basis. This rental solution makes it easier for
                   you to enjoy the benefits of solar power without the need for a large initial investment. 
                   With our rental plan, you can start saving on your electricity bills from day one while 
                   paying a manageable monthly fee. After the rental period, the system can be yours, making 
                   it a cost-effective and convenient way to transition to renewable energy. Our rental 
                   solutions are designed to provide financial flexibility and immediate access to the advantages of solar power.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* BENEFIT CARDS */}
      <div className="gray d-block py-5 mt-2" id="benCards">
        <section className="pt-5 mb-3" data-aos="fade-up">
          <div className="container">
            <div className="row g-0 bg-white nopadding noPadding shadow benefits-card">
              <div className="col-lg-6 order-lg-1 ">
                <img 
                  src={carbon}
                  alt="Load Shedding"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                    overflow: "hidden",
                    width:"100%",
                    height:"100%"}}
                  />
              </div>
              <div className="col-lg-6 p-4 p-md-10 order-lg-2">
                <h3 className="fw-normal">
                  Sell Your CO<sub>2</sub> Avoidance
                </h3>
                <p className="mt-4">
                  Your Solar System avoids the emission of CO<sub>2</sub> by
                  creating Clean Energy. We help you monetize this avoidance, by
                  connecting you with a German NGO, which subsidizes South
                  African PV-Systems. The German NGO gains donations from
                  long-haul flight passangers that give money in exchange for
                  their flight's CO<sub>2</sub> emmisions. We connect you to
                  this organization and you can receive gains from their
                  subsides of up to 20% of your installation costs.
                  </p>
                  <h5 className="text-primary text-center p-2 mt-5">You end up paying only every second panel!</h5>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 mb-3" data-aos="fade-up">
          <div className="container">
            <div className="row g-0 bg-white nopadding noPadding shadow benefits-card">
              <div className="col-lg-6 order-lg-2 ">
                
              <img
                src={savings}
                alt="Save money"
                style={{objectFit: "cover",
                  objectPosition: "center",
                  overflow: "hidden",
                  width:"100%",
                  height:"100%"}}
              />
                
              </div>
              <div className="col-lg-6 p-4 p-md-10 order-lg-1">
                <h3 className="fw-normal">
                  The savings on your electricity bill 
                  are the return on your investment
                </h3>
                <p className="mt-4">
                  Convert your electricity supply to self-generated solar.
                  Receive{" "}
                  <a
                    href="https://mybroadband.co.za/news/energy/437354-how-much-you-can-save-on-electricity-by-switching-to-solar.html#:~:text=South%20Africans%20can%20convert%20a,while%20paying%20off%20their%20system."
                  >
                    compelling savings
                  </a>{" "}
                  on your monthly energy bill with Mantula's PV-System. The more
                  solar panels that are installed the more your electricity
                  consumption will be handled by your new solar system.
                </p>
                

                <ul className="border-0">
                  <li className="list-group-item mt-5">
                    <div className="d-flex flex-row">
                      <i
                        className="p-2 bi bi-check-circle-fill"
                        style={{ fontSize: "1.3rem" }}
                      ></i>
                      <h5 className="p-2">Escape Rising Electricity Bills</h5>
                    </div>
                  </li>
                  <li className="list-group-item mt-3">
                    <div className="d-flex flex-row">
                      <i
                        className="p-2 bi bi-check-circle-fill"
                        style={{ fontSize: "1.3rem" }}
                      ></i>
                      <h5 className="p-2">Generate Savings</h5>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5" data-aos="fade-up">
          <div className="container">
            <div className="row g-0 bg-white nopadding noPadding shadow benefits-card">
              <div className="col-lg-6 order-lg-1 ">
                
              <img
                src={loadshedding}
                alt="Load shedding"
                style={{objectFit: "cover",
                  objectPosition: "center",
                  overflow: "hidden",
                  width:"100%",
                  height:"100%"}}
              />
                
              </div>
              <div className="col-lg-6 p-4 p-md-10 order-lg-2">
                <h3 className="fw-normal">Fight Against Load Shedding</h3>
                <p className="mt-4">
                  Keep the lights on when load shedding strikes. Be productive when others can't.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="border-0">
                      <li className="list-group-item mt-3">
                        <div className="d-flex flex-row">
                          <i
                            className="p-2 bi bi-exclamation-diamond-fill"
                            style={{ fontSize: "1.3rem" }}
                          ></i>
                          <h5 className="p-2">
                            355 days of load shedding in 2023
                          </h5>
                        </div>
                      </li>
                      <li className="list-group-item mt-3">
                        <div className="d-flex flex-row">
                          <i
                            className="p-2 bi bi-exclamation-diamond-fill"
                            style={{ fontSize: "1.3rem" }}
                          ></i>
                          <h5 className="p-2">Eskom has inflicted power outages due to load shedding since 2008</h5>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="border-0">
                      <li className="list-group-item mt-3">
                        <div className="d-flex flex-row">
                          <i
                            className="p-2 bi bi-check-circle-fill"
                            style={{ fontSize: "1.3rem" }}
                          ></i>
                          <h5 className="p-2">
                            Maintain power when Eskom load sheds your district
                          </h5>
                        </div>
                      </li>
                      <li className="list-group-item mt-3">
                        <div className="d-flex flex-row">
                          <i
                            className="p-2 bi bi-check-circle-fill"
                            style={{ fontSize: "1.3rem" }}
                          ></i>
                          <h5 className="p-2">Energy Freedom</h5>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="container py-5">
        <div className="text-center">
          <h2>
            Save Money and Go Green with a Power Purchase Agreement (PPA)
          </h2>
          <hr />
          <p>
          With our Power Purchase Agreement (PPA), you can enjoy a free solar installation on your property. You'll purchase the solar energy generated by our systems at a fixed rate, which is significantly lower than Eskom's ever-increasing prices for non-renewable energy. This means substantial savings for you in the long run. In addition, after a set period (typically 12 to 15 years), ownership of the solar system will transfer to you. Embrace the benefits of solar power, save money, and contribute to a greener future with Mantula Solar's eco-friendly PV system.
          </p>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <button className="btn btn-primary btn-block col-5 py-2">
            <a
              href="/ppa"
              className="text-light"
              style={{ textDecoration: "none" }}
            >
              <b>Learn more</b>
            </a>
          </button>
        </div>
      </div>
    </>
  );
}
