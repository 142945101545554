import React, { useState } from "react";
import service from "../services/MessageService";

import { message } from "../class/message";

import Spinner from "../components/calculator/common/Spinner";

export default function MessageUs() {
  let [firstName, setFirstName] = useState("");
  let [lastName, setLastName] = useState("");
  let [phone, setPhone] = useState("");
  let [email, setEmail] = useState("");
  let [messageText, setMessageText] = useState("");
  let [submittedForm, setSubmittedForm] = useState(false);
  let [formSending, setFormSending] = useState(false);

  async function submitForm(e) {
    e.preventDefault();
    try {
      setFormSending(true);

      const newMessage = new message(
        (firstName + " " + lastName), email, phone, messageText,
      );
      await service.submitMessage(newMessage);

      setSubmittedForm(true);
      setFormSending(false);
      setFirstName("");
      setLastName("");
      setPhone("");
      setEmail("");
      setMessageText("");
    } catch (err) {
      console.log("An error occurred while sending the message");
    }
  }

  function unHideForm() {
    setSubmittedForm(false);
  }

  return (
    <div className="container section-title-container">
      <div className="message text-center pt-4">
      { submittedForm ?
          <div className="mt-5 pb-5">
            <h2 className="text-primary fw-normal">Thank you for contacting us!</h2>
            <h3>We will get back to you as soon as possible</h3>
            <button onClick={unHideForm} className="btn btn-primary px-3 mt-4">Send another message</button>
          </div>
        :
        <>{ formSending ?
          <Spinner minHeight={467} />
        :
          <>
            <h2 className="text-center">Message Us</h2>
            <h3 className="text-center">
              We are here to provide solar for you. Any questions? Send us a message.
            </h3>
            <form
            onSubmit={(e) => {
              submitForm(e);
            }}
          >
            <div
              className="row justify-content-center py-0 mt-4 message-us-row"
            >
              <div className="col-12 col-sm-6 mt-2">
                <div className="mb-2">
                  <input
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    required
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6 mt-2">
                <div className="mb-2">
                  <input
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    required
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6 mt-2">
                <div className="mb-2">
                  <input
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    required
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6 mt-2">
                <div className="mb-2">
                  <input
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    type="tel"
                    className="form-control"
                    placeholder="Phone"
                    required
                  />
                </div>
              </div>
            </div>

            <div
              className="row justify-content-center message-us-row py-0 mt-2"
            >
              <div className="col-12">
                <textarea
                  value={messageText}
                  onChange={(e) => {
                    setMessageText(e.target.value);
                  }}
                  className="form-control py-2"
                  rows="5"
                  placeholder="Message"
                  required
                ></textarea>
              </div>
            </div>
            <div
              className="row justify-content-center"
            >
              <div className="text-center">
                <button className="btn btn-primary p-2 px-4">Send Message</button>
              </div>
            </div>
            </form>
          </>
        }</>
      }
      </div>
    </div>
  );
}
