import React, { useState } from "react";
import { Controls } from "react-decision-tree-flow";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { useUserSelectionContext } from "../context/UserSelectionContext";

import { enquiries } from "../models/enquiries";

import service from "../../../services/MessageService";

import StepWrapper from "../common/StepWrapper";
import Spinner from "../common/Spinner";

export default function Enquiry({ calcName }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [additionalNotes, setadditionalNotes] = useState("");

  const {userSelection, setUserSelection} = useUserSelectionContext();

  // default == enquiry form shown
  // submitting == spinner shown
  // submitted == thank you message shown
  const [formSubmitting, setFormSubmitting] = useState("default");

  function resetStates() {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setadditionalNotes("");
  }

  function convertUserSelectionsToString() {
    let arr = Object.values(userSelection).filter(el => {
      return el !== '';
    })

    return arr.join('<i class="bi bi-chevron-compact-right mx-1"></i>');
  }

  function convertUserSelectionsToStringForEmail() {
    let arr = Object.values(userSelection).filter(el => {
      return el !== '';
    })

    return arr.join(' > ');
  }

  async function onFormSubmit(e, quote) {
    e.preventDefault();

    try {
      setFormSubmitting("submitting");

      let readyQuote = quote ? calcName + " " + quote : "No quote";

      const newEnquiry = new enquiries(
        firstName,
        lastName,
        email,
        phone,
        address,
        convertUserSelectionsToStringForEmail(),
        readyQuote,
        additionalNotes,
      );

      await service.submitEnquiry(newEnquiry);

      setFormSubmitting("submitted");
      resetStates();
    } catch (err) {
      setFormSubmitting("shown")
      console.log("Error submitting the enquiry");
    }
  }

  return (
    <Controls>
      {({ destinations, back, data }) => (
        <StepWrapper
          title={
            formSubmitting !== "submitted" ?
              'Make an <span className="text-orange">Enquiry</span> <br/> <span className="fs-5">' + convertUserSelectionsToString() + "</span>"
            :
              ''
          }
        >
          {formSubmitting === "submitting" ?
              <Spinner minHeight={690} />
            :
            <>{formSubmitting === "submitted" ?
              <div className="mt-5 pb-5">
                <h2 className="text-primary fw-normal">Thank you for contacting us!</h2>
                <h3>We will get back with you as soon as possible</h3>
                <button onClick={destinations[1]} className="btn btn-primary px-3 mt-4">Start from beginning</button>
              </div>
              :
              <div className="calculator-enquiry px-0 px-lg-5">
                <Form onSubmit={(event) => onFormSubmit(event, data)}>
                  <div className="row mx-3">
                    <div className="col-12 col-lg-6">
                      <Form.Group className="mb-3">
                        <Form.Label className="d-block text-start">First name</Form.Label>
                        <Form.Control
                          type="text"
                          required={true}
                          value={firstName}
                          onChange={(e) => {setFirstName(e.target.value)}}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-12 col-lg-6">
                      <Form.Group className="mb-3">
                        <Form.Label className="d-block text-start">Last name</Form.Label>
                        <Form.Control
                          type="text"
                          value={lastName}
                          onChange={(e) => {setLastName(e.target.value)}}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12">
                      <Form.Group className="mb-3">
                        <Form.Label className="d-block text-start">Email address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="name@example.com"
                          required={true}
                          value={email}
                          onChange={(e) => {setEmail(e.target.value)}}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label className="d-block text-start">Phone</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="+27 00 000 0000"
                          required={true}
                          value={phone}
                          onChange={(e) => {setPhone(e.target.value)}}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label className="d-block text-start">Address</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="123 Main st."
                          required={true}
                          value={address}
                          onChange={(e) => {setAddress(e.target.value)}}
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-block text-start">Additional notes</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          placeholder="Additional notes"
                          value={additionalNotes}
                          onChange={(e) => {setadditionalNotes(e.target.value)}}
                        />
                      </Form.Group>

                      <div className="row">
                        <div className="d-grid col-12 col-lg-6 mt-3">
                          <button type="button" className="btn btn-secondary" onClick={() => back(data)}>
                            Go Back
                          </button>
                        </div>
                        <div className="d-grid col-12 col-lg-6 mt-3">
                          <Button type="submit" className="btn-orange">
                            Submit Enquiry
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
}           </>
          }
        </StepWrapper>
      )}
    </Controls>
  );
}
