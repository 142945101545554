export class enquiries {
  constructor(
    firstName,
    lastName,
    email,
    phone,
    address,
    systemDetails,
    quote,
    additionalNotes
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.address = address;
    this.systemDetails = systemDetails;
    this.quote = quote;
    this.additionalNotes = additionalNotes;
  }
}
