import { Controls } from "react-decision-tree-flow";

import StepWrapper from "../common/StepWrapper";
import SpecsCard from "../common/SpecsCard";

export default function SpecsCards({ title, cards, backButton }) {
  return (
    <Controls>
      {({ destinations, back }) => (
        <StepWrapper
          title={title}
          backButton={backButton}
          back={back}
        >
          <div className="row justify-content-center">
            {
              cards.map((card) => {
                return <div key={cards.indexOf(card)} className="col-11 col-lg-4 mt-2">
                  <SpecsCard
                    title={card.title}
                    description={card.description}
                    specs={card.specs}
                    destination={() =>
                      card.nextStep && card.nextStep === "quote" ?
                        destinations[card.nextStep](card.quote)
                      :
                        destinations[card.nextStep]()
                    }
                    userData={{ batteryPackage: card.title }}
                  />
                </div>
              })
            }
          </div>
        </StepWrapper>
      )}
    </Controls>
  );
}
