import { Helmet, HelmetProvider } from "react-helmet-async";

export default function SEO({ title }) {
  return (
    <HelmetProvider>
      <Helmet>
        <meta name="description" content="Mantula Solar Test Site" />
        <meta name="keywords" content="solar, solar solutions, south africa solar, mantula, mantula solar, solar solutions south africa" />
        <title>{title}</title>
      </Helmet>
    </HelmetProvider>
  );
}
