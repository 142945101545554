import { useUserSelectionContext } from "../context/UserSelectionContext";

export default function DescriptionCard({
  title,
  subtitle,
  description,
  icon,
  destination,
  userData,
  href,
}) {
  const { userSelection, setUserSelection } = useUserSelectionContext();

  function handleOnClick() {
    // Set UserSelection data
    Object.assign(userSelection, userData);
    setUserSelection({ ...userSelection });
    
    destination();
  }

  return (
    <div className="icon-card pt-3 pb-3 p-4">
      <h4 className="text-center mb-1 mt-3" style={{ height: "15%" }}>
        {title}
      </h4>
      <p className="text-center mb-3 subtitle">{subtitle}</p>
      <div className="row pb-2 pt-4">
        <div className="text-start">
          <p>
            {description}
          </p>
        </div>
        <div style={{textAlign: "right"}}>
          <a href={href}
            // className="text-right"
            // style={{ textAlign: "right"}}
            >
              learn more...
            </a>
        </div>
      </div>
      <div className="text-center d-grid">
        <button className="btn btn-orange" onClick={handleOnClick}>
          <i className={"text-white bi " + icon} />
          Select
        </button>
      </div>
    </div>
  );
}
