import React, { useEffect } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

import Header from "./common/Header";
import Footer from "./common/Footer";

// IMPORT PAGES
import Benefits from "./components/Benefits";
import Commercial from "./components/Commercial";
import Contact from "./components/Contact";
import Ppa from "./components/Ppa";
import Home from "./components/Home";
import Testimonials from "./components/Testimonials";
import Residential from "./components/Residential";
import AboutUs from "./components/AboutUs";
import WhySolar from "./components/WhySolar";

import "./assets/css/main.scss";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "bootstrap/js/dist/util";

export default function App() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/testimonials" element={<Testimonials />}></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route path="/why-solar" element={<WhySolar />}></Route>
        <Route path="/ppa" element={<Ppa />}></Route>
        <Route path="/benefits" element={<Benefits />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/residential" element={<Residential />}></Route>
        <Route path="/commercial" element={<Commercial />}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
