import { Controls } from "react-decision-tree-flow";

import VerticalSeparator from "../common/VerticalSeparator";

export default function QuoteContent({ quote }) {
    return (
    <Controls>
      {({ destinations, back, data }) => (
        <div className="row mx-3">
          <div className="col-12 col-lg">
            <h3>DESCRIPTION</h3>
            <p className="mt-2 text-start">
              {quote.description}
            </p>
            <ul className="system-features text-start">
              { quote.features.map((q) => {
                return <li key={Math.random()}><i className={"bi " + q.iconClass + " text-orange"} /> {q.text}</li>
              })}
            </ul>
          </div>

          <VerticalSeparator />

          <div className="col-12 col-lg mt-4 mt-lg-0">
            <h3>CAN POWER</h3>
            <ul className="system-features text-start">
              { quote.powers.map((q) => {
                return <li key={Math.random()}><i className={"bi " + q.iconClass + " text-orange"} /> {q.text}</li>
              })}
            </ul>
          </div>

          <VerticalSeparator />

          <div className="col-12 col-lg mt-4 mt-lg-0">
            <h3>PRICE ESTIMATE</h3>
            <h5 className="mt-4">Your estimated price is</h5>
            <span className="badge badge-success d-grid align-items-center">
              <h3 className="m-0 p-1">R {quote.price.toLocaleString(undefined)}</h3>
            </span>
            <p className="d-grid">
              VAT inclusive
            </p>
            <hr/>
            <h5 className="mt-4">Or rent the system at</h5>
            <span className="badge badge-success d-grid align-items-center">
              <h3 className="m-0 p-1">R {quote.rent.toLocaleString(undefined)}</h3>
            </span>
            <p className="d-grid">
              for 72 months - then you buy it for R1
            </p>

            <div className="d-grid align-items-center">
              <button className="btn btn-orange mt-2 mb-2" onClick={() => destinations['enquiry'](quote.quoteNumber)}>
                Make an enquiry
              </button>
              <button className="btn btn-secondary" onClick={destinations[1]}>
                Start Over
              </button>
            </div>
          </div>
        </div>
      )}
    </Controls>
  );
}