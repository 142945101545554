import React from "react";
import watercolor from "../assets/img/WaterColor.png";


export default function GetQuoteModal({ handleClose }) {
  const handleClick = () => {
    handleClose();
  };

  return (
    <div className="container section-title-container text-center bg-light">
      <div className="position-relative">
        <img src={watercolor} alt="Orange Watercolor" className="d-lg-inline watercolor-img" />
      </div>
      {/* <div>
        <h2 className="position-relative text-secondary mb-4 wc-text">Get a free quote</h2>
      </div> */}
        <a
          href="/residential#calculator"
          className="mt-3 mx-4 px-3 btn btn-secondary fs-5"
          onClick={handleClick}
        >
          Residential Customers
        </a>
        <a
          href="/commercial#calculator"
          className="mt-3 mx-4 px-3 btn btn-secondary fs-5"
          onClick={handleClick}
        >
          Commercial Customers
        </a>
    </div>
  );
}
