import React, { useState, useEffect } from "react";
import { Step, Wizard } from "react-decision-tree-flow";

import "./calculator/assets/calculator.css";

import UserSelectionProvider, { useUserSelectionContext } from "./calculator/context/UserSelectionContext";

import calculatorService from "./calculator/services/calculator.service";

import DescriptionCards from "./calculator/calculatorSteps/DescriptionCards";
import SpecsCards from "./calculator/calculatorSteps/SpecsCards";
import PeopleSlider from "./calculator/calculatorSteps/PeopleSlider";
import BasicButtons from "./calculator/calculatorSteps/BasicButtons";
import Quote from "./calculator/calculatorSteps/Quote";
import Enquiry from "./calculator/calculatorSteps/Enquiry";
import Spinner from "./calculator/common/Spinner";

const Calculator = ({ name }) => {
  const [calculator, setCalculator] = useState(null);
  const [tree, setTree] = useState(null);
  const [firstStep, setFirstStep] = useState(null);

  const {userSelection, setUserSelection} = useUserSelectionContext();

  useEffect(() => {
    getCalculator(name);
  }, []);

  async function getCalculator(name) {
    try {
      let calc = await calculatorService.getCalculatorByName(name);
      setCalculatorTree(calc);
      setCalculator(calc);
      setSystemType(calc);
    } catch(err) {
      console.log("There was an error loading the calculator");
    }
  }

  function setCalculatorTree(calc) {
    let tree = {};

    let firstStep = String(calc.steps.find(el => el.stepNumber === 1).stepNumber);
    setFirstStep(firstStep);

    // Get every step and their next step
    calc.steps.forEach(step => {
      let stepNumber = step.stepNumber;
      let nextSteps = [];

      if(step.cards) {
        step.cards.forEach(card => {
          if(card.nextStep && !nextSteps.includes(card.nextStep)) {
            nextSteps.push(String(card.nextStep));
          }
        });
      }
      if(step.sliderSteps) {
        step.sliderSteps.forEach(sliderStep => {
          if(sliderStep.nextStep && !nextSteps.includes(sliderStep.nextStep)) {
            nextSteps.push(String(sliderStep.nextStep));
          }
        })
      }
      if(step.buttons) {
        step.buttons.forEach(button => {
          if(button.nextStep && !nextSteps.includes(button.nextStep)) {
            nextSteps.push(String(button.nextStep));
          }
        });
      }

      tree[String(stepNumber)] = nextSteps;
    });

    tree["quote"] = [firstStep, "enquiry"];
    tree["enquiry"] = [firstStep];

    setTree(tree);
  }

  function setSystemType(calc) {
    if(calc != null) {
      // Set System Type (from Calculator name) for UserSelection
      let systemType = calc.name.charAt(0).toUpperCase() + calc.name.slice(1);

      Object.assign(userSelection, { systemType: systemType });
      setUserSelection({...userSelection});
    }
  }

  return (
    <UserSelectionProvider>
      <div className="card card-body pb-4 px-0 px-lg-4" id="calculator">
        <Wizard tree={tree != null ? tree : {}} first={firstStep !== null ? firstStep : "1"}>
          {tree && calculator != null ?
            <>
            {
              Object.keys(tree).map((key) => {
                let step = calculator.steps.find(step => step.stepNumber == key);

                return <Step key={key} name={key}>
                  {
                    step ?
                      step.stepType === 'descriptionCards' ?
                        <DescriptionCards
                          title={step.title}
                          cards={step.cards}
                          backButton={step.stepNumber == 1 ? false : true}
                        />
                      : step.stepType === 'specsCards' ?
                        <SpecsCards
                          title={step.title}
                          cards={step.cards}
                          backButton={step.stepNumber == 1 ? false : true}
                        />
                      : step.stepType === 'peopleSlider' ?
                        <PeopleSlider
                          title={step.title}
                          steps={step.sliderSteps}
                          backButton={step.stepNumber == 1 ? false : true}
                        />
                      : step.stepType === 'basicButtons' ?
                        <BasicButtons
                          title={step.title}
                          buttons={step.buttons}
                          backButton={step.stepNumber == 1 ? false : true}
                        />
                      :
                      <></>
                    :
                    <></>
                  }
                </Step>
              })
            }
            <Step name="quote">
              <Quote
                quotes={calculator.quotes}
                backButton={true}
              />
            </Step>
            <Step name="enquiry">
              <Enquiry
                calcName={calculator.name}
              />
            </Step>
          </>
          :
            <Spinner minHeight={500} />
          }
        </Wizard>
      </div>
    </UserSelectionProvider>
  );
};

export default Calculator;