import SEO from "../common/SEO";
import Message from "./MessageUs";

export default function Contact() {
  return (
    <div>
      {/* SEO */}
      <SEO
        title="Mantula Solar: Contact Us"
      />

      {/* MESSAGE */}
      <Message />

      {/* CONTACT US */}
      <div className="bg-light">
        <div className="container section-title-container text-center">
          <h2 className="text-center pt-4">Contact Us</h2>
          <h3>Monday - Friday: 8:00 AM to 4:00 PM</h3>
          <div className="row justify-content-md-center">
            <a href="tel:+27657003395" className="col-lg-4">
              <div className="text-center">
                <i className="bi bi-telephone" style={{fontSize: "3rem"}}></i>
                <h4 className="text-dark">Phone</h4>
                <p className="text-dark">021 201-7342</p>
              </div>
            </a>
            <a href="mailto:info@mantula.co.za" className="col-lg-4">
              <div className="text-center">
                <i className="bi bi-envelope" style={{fontSize: "3rem"}}></i>
                <h4 className="text-dark">Email</h4>
                <p className="text-dark">info@mantula.co.za</p>
              </div>
            </a>
            <a href="https://www.google.com/maps/place/Mantula+Solar/@-33.9654228,18.8375065,18z/data=!4m5!3m4!1s0x1dcdb36f3c578f6f:0x182a46d39dca933d!8m2!3d-33.9645147!4d18.8368421" target="_BLANK" className="col-lg-4" rel="noreferrer">
              <div className="text-center">
                <i className="bi bi-geo-alt" style={{fontSize: "3rem"}}></i>
                <h4 className="text-dark">Location</h4>
                <p className="mb-0 text-dark">39 Gemini Street</p>
                <p className="mt-0 text-dark">Brackenfell Industrial, Cape Town, 7560</p>
              </div>
            </a>
          </div>
          <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d38765.683741072804!2d18.82788848741134!3d-33.94836733043947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcdb36f3c578f6f%3A0x182a46d39dca933d!2sMantula%20Solar!5e0!3m2!1sen!2sza!4v1632921346248!5m2!1sen!2sza"
              style={{width: "100%", height: "350px", border: "0", borderRadius: '1em'}}
              allowFullScreen="yes"
              loading="lazy"
              title="Map"
              frameBorder="0"
              className="shadow"
            >
            </iframe>
        </div>
      </div>
    </div>
  );
}
