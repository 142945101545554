import { doc, getDoc, getDocs, collection, query, where } from "firebase/firestore";

import { db } from '../../../firebase/firebase';

import Calculator from "../models/Calculator";

class CalculatorService {
  constructor() {
    this.collection = 'calculators';
  }

  async getCalculatorByName(name) {
    // Get calculator by name
    const collectionReference = collection(db, this.collection);
    const q = query(collectionReference, where('name', '==', name));

    const querySnapshot = await getDocs(q);

    let calcId = null;
    let calcName = name;
    let calcSteps = null;
    let calcQuotes = null;

    querySnapshot.forEach(async (doc) => {
      let data = doc.data();

      calcId = doc.id;
      calcName = data.name;
    });

    // Get nested collection (steps)
    calcSteps = await getCalculatorSteps(calcId);

    //  Get nested collection (quotes)
    calcQuotes = await getCalculatorQuotes(calcId);
    calcQuotes.sort((a, b) => a.quoteNumber - b.quoteNumber)

    let calculator = new Calculator(calcId, calcName, calcSteps, calcQuotes);
    return calculator;
  }
}

async function getCalculatorSteps(id) {
  let calcSteps = [];

  const stepsRef = collection(db, 'calculators', id, "steps");
  const stepsSnapshot = await getDocs(stepsRef);

  stepsSnapshot.forEach((step) => {
    let stepData = step.data();
    calcSteps.push(stepData);
  });

  return calcSteps;
}

async function getCalculatorQuotes(id) {
  let calcQuotes = [];

  const quotesRef = collection(db, 'calculators', id, "quotes");
  const quotesSnapshot = await getDocs(quotesRef);

  quotesSnapshot.forEach((quote) => {
    let quoteData = quote.data();
    calcQuotes.push(quoteData);
  });
  
  return calcQuotes;
}

const calculatorService = new CalculatorService();
export default calculatorService;