export default class UserSelection {
  constructor() {
    this.systemType = '';
    this.solutionType = '';
    this.batteryPackage = '';
    this.householdSize = '';
    this.currentBill = '';
    this.electricityUsageTimeofDay = '';
    this.elecricityUsageNight = '';
  }
}