import React, { useState, useContext } from 'react';

import UserSelection from "../models/UserSelection";

const userSelectionObj = new UserSelection();
const UserSelectionContext = React.createContext({ userSelection: userSelectionObj, setUserSelection: () => {} });

export function UserSelectionProvider({ children }) {
  const [userSelection, setUserSelection] = useState(userSelectionObj);

  return (
    <UserSelectionContext.Provider value={{ userSelection, setUserSelection }}>
      {children}
    </UserSelectionContext.Provider>
  );
}

export const useUserSelectionContext = () => useContext(UserSelectionContext);
export default UserSelectionProvider;